import React, { useState } from 'react';
import Flag from 'react-world-flags';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { useTranslation } from 'react-i18next'
import { useGlobalState } from './context/globalContext';
const LanguageSelector = () => {
  const { i18n } = useTranslation();
  const { language, updateLanguage } = useGlobalState();
  // const [selectedLanguage, setSelectedLanguage] = useState('en');
  // debugger;
  // const handleLanguageChange = (lang) => {
  //   setSelectedLanguage(lang);
  //   if (onLanguageChange) {
  //     i18n.changeLanguage(lang);
  //     document.documentElement.dir = lang === 'ar' ? 'rtl' : 'ltr';
  //     onLanguageChange(lang);
  //   }
  // };
  const isRtl = i18n.language === 'ar';
  return (
    <Menu as="div" className={` ml-2  relative inline-block text-left ${isRtl ? 'ml-2 text-right' : ''}`}>
      <div>
        <MenuButton className=" inline-flex w-32 h-10 items-center justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 ">
          <Flag code={i18n.language === 'en' ? 'US' : 'SA'} alt={i18n.language} className={`w-6 h-6 mr-2 ${isRtl ? 'ml-2' : ''}`} />
          {i18n.language === 'en' ? 'English' : 'Arabic'}
          <ChevronDownIcon className={`-mr-1 ml-2 h-5 w-5 text-gray-400 ${isRtl ? 'ml-2' : ''}`} aria-hidden="true" />
        </MenuButton>
      </div>

      <MenuItems
        transition
        className=" absolute  inline-flex w-32  text-sm font-medium text-gray-700  z-10 mt-2 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
      >
        <div className="py-1">
          <MenuItem>
            <button
              onClick={() => updateLanguage('en')}
              className={`block w-full px-4 py-2 text-sm text-left text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 ${isRtl ? 'ml-2 text-right' : ''}`}
            >
              <Flag code="US" alt="English" className={`w-6 h-6 inline-block mr-2 ${isRtl ? 'ml-2' : ''}`} />
              English
            </button>
          </MenuItem>
          <MenuItem>
            <button
              onClick={() => updateLanguage('ar')}
              className={`block w-full px-4 py-2 text-sm text-left text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2${isRtl ? 'ml-2 text-right' : ''}`}
            >
              <Flag code="SA" alt="Arabic" className={`w-6 h-6 inline-block mr-2 ${isRtl ? 'ml-2' : ''}`} />
              Arabic
            </button>
          </MenuItem>
        </div>
      </MenuItems>
    </Menu>
  );
};

export default LanguageSelector;