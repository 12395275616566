import React from 'react';

const PlaceOrderEmail = ({ order = {} }) => {
    const { id, items = [], total = 0, shipping = {} } = order;
    return (
        <div className="bg-white p-6 rounded-lg shadow-md max-w-2xl mx-auto">
          <h1 className="text-2xl font-bold text-gray-800 mb-4">Order Confirmation</h1>
          <p className="text-gray-600 mb-2">Thank you for your order!</p>
          <p className="text-gray-600 mb-4">
            Your order number is <span className="font-semibold">{id || 'N/A'}</span>.
          </p>
          
          <h2 className="text-lg font-semibold text-gray-800 mb-2">Order Details</h2>
          <div className="border border-gray-300 rounded-lg p-4 mb-4">
            {items.length > 0 ? items.map((item, index) => (
              <div key={index} className="flex justify-between mb-2">
                <span className="text-gray-700">{item.name}</span>
                <span className="text-gray-700">{item.quantity} x ${item.price.toFixed(2)}</span>
              </div>
            )) : <p className="text-gray-600">No items in this order.</p>}
            <div className="border-t border-gray-300 pt-2 mt-2">
              <div className="flex justify-between font-semibold">
                <span>Total</span>
                <span>${total.toFixed(2)}</span>
              </div>
            </div>
          </div>
    
          <h2 className="text-lg font-semibold text-gray-800 mb-2">Shipping Information</h2>
          <p className="text-gray-600 mb-1">{shipping.name || 'N/A'}</p>
          <p className="text-gray-600 mb-1">{shipping.address || 'N/A'}</p>
          <p className="text-gray-600 mb-4">
            {shipping.city || ''}, {shipping.state || ''} {shipping.zip || ''}
          </p>
    
          <p className="text-gray-600 mb-2">
            If you have any questions about your order, please contact us at <a href="mailto:support@example.com" className="text-blue-600">support@example.com</a>.
          </p>
    
          <p className="text-gray-500 text-sm">Thank you for shopping with us!</p>
        </div>
      );
    };
    
    export default PlaceOrderEmail;