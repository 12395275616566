import React, { useEffect, useState, useCallback } from 'react';
import { GoogleMap, LoadScript, Marker, useJsApiLoader } from '@react-google-maps/api';

const API_KEY = 'AIzaSyBiFSvbtlW9ovAK0RG1VQvQVLkq2z_y1WY';

const containerStyle = {
  width: '100%',
  height: '400px',
};

const center = { lat: 37.7749, lng: -122.4194 };

const SaudiCheckout = () => {
  const [selectedLocation, setSelectedLocation] = useState(center);
  const [address, setAddress] = useState('');
  const [message, setMessage] = useState('');
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: API_KEY,
    libraries: ['places']
  });

  const handleMapClick = useCallback((event) => {
    const newPosition = {
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    };
    setSelectedLocation(newPosition);
  }, []);

  const handleRequestLocation = () => {
    navigator.geolocation.getCurrentPosition(position => {
      const { latitude, longitude } = position.coords;
      setSelectedLocation({ lat: latitude, lng: longitude });
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setMessage(`Location saved: ${address} (Lat: ${selectedLocation.lat}, Lng: ${selectedLocation.lng})`);
    setAddress('');
  };

  const handleAddressChange = (event) => {
    setAddress(event.target.value);
  };

  useEffect(() => {
    if (isLoaded) {
      const map = new window.google.maps.Map(document.getElementById("map"), {
        center: selectedLocation,
        zoom: 14,
      });

      const marker = new window.google.maps.Marker({
        position: selectedLocation,
        map: map,
      });

      // Update marker position and map center on location change
      const moveMarker = (latLng) => {
        marker.setPosition(latLng);
        map.setCenter(latLng);
      };

      map.addListener('click', (event) => {
        const newLatLng = {
          lat: event.latLng.lat(),
          lng: event.latLng.lng(),
        };
        setSelectedLocation(newLatLng);
        moveMarker(event.latLng);
      });
      
      // Cleanup function to remove the event listener
      return () => {
        new window.google.maps.event.clearListeners(map, 'click');
      };
    }
  }, [isLoaded, selectedLocation]);

  return isLoaded ? (
    <div className="container mx-auto p-6">
      {/* <h2 className="text-2xl font-bold mb-4">Select Location for Shipping</h2> */}
      <div className="bg-white shadow-md rounded-lg p-6">
        <div id="map" style={containerStyle}></div>
        <div className="mt-6">
          <h3 className="text-lg font-semibold mb-2">Location Details</h3>
          <p className="text-gray-700">Latitude: {selectedLocation.lat}</p>
          <p className="text-gray-700">Longitude: {selectedLocation.lng}</p>
        </div>
        <div className="mt-6">
          <h3 className="text-lg font-semibold mb-2">Address Information</h3>
          <form className="space-y-4" onSubmit={handleSubmit}>
            <div>
              <label htmlFor="address" className="block text-gray-700">Address</label>
              <input
                id="address"
                type="text"
                className="mt-1 block w-full border border-gray-300 rounded-lg p-2"
                placeholder="Enter your address"
                value={address}
                onChange={handleAddressChange}
              />
            </div>
            <div>
              <button
                type="submit"
                className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600"
              >
                Save Location
              </button>
              <button
                type="button"
                onClick={handleRequestLocation}
                className="ml-4 bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600"
              >
                 Current Location
              </button>
            </div>
          </form>
          {message && <p className="mt-4 text-green-600">{message}</p>}
        </div>
      </div>
    </div>
  ) : (
    <div>Loading...</div> 
  );
};

export default SaudiCheckout ;
