import React, { createContext, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
// Create context
const GlobalContext = createContext();

// Context provider
export const GlobalContextProvider = ({ children }) => {
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState(
    localStorage.getItem("language") || "en"
  ); // Default language
  const [currency, setCurrency] = useState(
    localStorage.getItem("currency") || "USD"
  ); // Default currency
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [cart, setCart] = useState(() => {
    const savedCart = localStorage.getItem("cart");
    return savedCart ? JSON.parse(savedCart) : [];
  });
  
  const updateLanguage = (newLanguage) => {
    setLanguage(newLanguage);
    localStorage.setItem("language", newLanguage); // Persist in local storage
    i18n.changeLanguage(newLanguage);
  };

  const updateCurrency = (newCurrency) => {
    setCurrency(newCurrency);
    localStorage.setItem("currency", newCurrency); // Persist in local storage
    setCart([]); // empty the cart on locality change as every locality is reflecting diiferent price for product
  };

  const calculateTotalAmount = () => {
    const total = cart.reduce((sum, item) => {
      const itemPrice = parseFloat(item.price);
      return sum + itemPrice * item.quantity;
    }, 0);
    setTotalAmount(total.toFixed(2));
  };

  const calculateTotalQuantity = () => {
    if (!Array.isArray(cart) || cart.length === 0) {
      setTotalQuantity(0);
    } else {
      setTotalQuantity(
        cart.reduce((total, item) => total + (item.quantity || 0), 0)
      );
    }
  };

  const increaseQuantity = (product) => {
    setCart((prevCart) => {
      const existingProduct = prevCart.find((item) => item.id === product.id);

      if (existingProduct) {
        // Update quantity of existing product
        return prevCart.map((item) =>
          item.id === product.id
            ? { ...item, quantity: item.quantity + 1 }
            : item
        );
      } else {
        // Add new product with quantity 1
        return [...prevCart, { ...product, quantity: 1 }];
      }
    });
  };

  const addToCart = (product) => {
    let productQuantity = product.quantity || 1;
    setCart((prevCart) => {
      const existingProduct = prevCart.find((item) => item.id === product.id);

      if (existingProduct) {
        // Update quantity of existing product
        return prevCart.map((item) =>
          item.id === product.id ? { ...item, quantity: productQuantity } : item
        );
      } else {
        // Add new product with quantity 1
        return [...prevCart, { ...product, quantity: productQuantity }];
      }
    });
  };

  const removeFromCart = (itemId) => {
    setCart((prevCart) => prevCart.filter((item) => item.id !== itemId));
  };

  const decreaseQuantity = (product) => {
    setCart((prevCart) => {
      const existingProduct = prevCart.find((item) => item.id === product.id);
      let updatedCart = [];
      if (existingProduct) {
        // Update quantity of existing product
        updatedCart = prevCart.map((item) =>
          item.id === product.id
            ? { ...item, quantity: item.quantity - 1 }
            : item
        );
      }
      return updatedCart.filter((item) => item.quantity > 0);
    });
  };

  const clearCart = () => {
    setCart([]);
  };

  useEffect(() => {
    calculateTotalAmount();
    calculateTotalQuantity();
    localStorage.setItem("cart", JSON.stringify(cart));
  }, [cart]);

  return (
    <GlobalContext.Provider
      value={{
        language,
        currency,
        cart,
        totalAmount,
        totalQuantity,
        addToCart,
        removeFromCart,
        clearCart,
        updateLanguage,
        updateCurrency,
        decreaseQuantity,
        increaseQuantity,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

// Custom hook to use context
export const useGlobalState = () => useContext(GlobalContext);
