// CurrencySelector.js
import React from 'react';
import Select from 'react-select';
import { useGlobalState } from './context/globalContext';

const options = [
  { value: 'USD', label: 'USD $' },
  { value: 'SAR', label: 'SAR ﷼' }
//  { value: 'EUR', label: 'EUR €' }
];

const CurrencySelector = () => {
  const { currency, updateCurrency } = useGlobalState();

  return (
    <Select
      options={options}
      value={options.find(option => option.value === currency)}
      onChange={(option) => updateCurrency(option.value)}
      className="ml-4 inline-flex w-32  text-sm font-medium text-gray-700 "
    />
  );
};

export default CurrencySelector;
