import React, { useEffect, useState } from 'react';
import axios from 'axios';

const API_KEY = 'AIzaSyBiFSvbtlW9ovAK0RG1VQvQVLkq2z_y1WY';

const Checkout = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [addressLine1, setAddressLine1] = useState('');
  const [addressLine2, setAddressLine2] = useState('');
  const [country, setCountry] = useState('');
  const [city, setCity] = useState('');
  const [district, setDistrict] = useState('');
  const [postCode, setPostCode] = useState('');
  const [showError, setShowError] = useState('');
  const [message, setMessage] = useState('');

  const handleRequestLocation = async () => {
    navigator.geolocation.getCurrentPosition(async position => {
      const { latitude, longitude } = position.coords;

      try {
        // Call the Geocoding API
        const geocodeResponse = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json`, {
          params: {
            latlng: `${latitude},${longitude}`,
            key: API_KEY,
          },
        });

        if (geocodeResponse.data.results.length > 0) {
          const addressComponents = geocodeResponse.data.results[0].address_components;

          // Extract necessary details
          addressComponents.forEach(component => {
            if (component.types.includes('country')) {
              setCountry(component.long_name);
            }
            if (component.types.includes('locality')) {
              setCity(component.long_name);
            }
            if (component.types.includes('administrative_area_level_2')) {
              setDistrict(component.long_name);
            }
            if (component.types.includes('postal_code')) {
              setPostCode(component.long_name);
            }
          });
          setAddressLine2(geocodeResponse.data.results[0].formatted_address);
        } else {
          setShowError('No address found for the current location.');
        }
      } catch (error) {
        console.error('Error retrieving address:', error);
        setShowError('Could not retrieve address. Please try again.');
      }
    });
  };

  const handleAddressSubmit = async () => {
    // Prepare the payload for submission
    const payload = {
      Ocode: localStorage.getItem('Order_Code'),
      first_name: firstName,
      last_name: lastName,
      email,
      phone,
      address1: addressLine1,
      address2: addressLine2,
      country,
      city,
      district,
      post_code: postCode,
    };

    try {
      // Send the address to your server
      const ret = await axios.post('https://liven.today/api/Orders/Address_Submit', payload);
      
      console.log(ret.data);
      if (ret.data.length === 0) {
        setShowError('Order not sent or invalid data');
      } else {
        setMessage('Location saved successfully.');
        
        const redirectURL = ret.data.toURL; // Assuming `toURL` is included in the response
      window.location.href = redirectURL; // Redirecting the user 
   
      }
      
    } catch (error) {
      console.error('Error submitting address:', error);
      setShowError('Could not submit address. Please try again.');
    }
  };

  return (
    <div className="container mx-auto p-6">
      <div className="bg-white shadow-md rounded-lg p-6">
        <h3 className="text-lg font-semibold mb-2">Address Information</h3>
        <input type="text" value={firstName} onChange={(e) => setFirstName(e.target.value)} placeholder="First Name" className="mt-1 block w-full border border-gray-300 rounded-lg p-2" required />
        <input type="text" value={lastName} onChange={(e) => setLastName(e.target.value)} placeholder="Last Name" className="mt-1 block w-full border border-gray-300 rounded-lg p-2" required />
        <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email" className="mt-1 block w-full border border-gray-300 rounded-lg p-2" required />
        <input type="tel" value={phone} onChange={(e) => setPhone(e.target.value)} placeholder="Phone" className="mt-1 block w-full border border-gray-300 rounded-lg p-2" required />
        <input type="text" value={addressLine1} onChange={(e) => setAddressLine1(e.target.value)} className="mt-1 block w-full border border-gray-300 rounded-lg p-2" placeholder="Address Line 1" />
        <input type="text" value={addressLine2} onChange={(e) => setAddressLine2(e.target.value)} className="mt-1 block w-full border border-gray-300 rounded-lg p-2" placeholder="Address Line 2" />
        <input type="text" value={country} onChange={(e) => setCountry(e.target.value)} className="mt-1 block w-full border border-gray-300 rounded-lg p-2" placeholder="Country" />
        <input type="text" value={city} onChange={(e) => setCity(e.target.value)} className="mt-1 block w-full border border-gray-300 rounded-lg p-2" placeholder="City" />
        <input type="text" value={district} onChange={(e) => setDistrict(e.target.value)} className="mt-1 block w-full border border-gray-300 rounded-lg p-2" placeholder="District" />
        <input type="text" value={postCode} onChange={(e) => setPostCode(e.target.value)} className="mt-1 block w-full border border-gray-300 rounded-lg p-2" placeholder="Post Code" />

        {showError && <p className="text-red-600">{showError}</p>}
        <div className="mt-6">
          <button
            onClick={handleAddressSubmit}
            type="submit"
            className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600"
          >
            Save Location
          </button>
          <button
            type="button"
            onClick={handleRequestLocation}
            className="ml-4 bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600"
          >
            Current Location
          </button>
        </div>

        {message && <p className="mt-4 text-green-600">{message}</p>}
      </div>
    </div>
  );
};

export default Checkout;
