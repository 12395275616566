import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';

const Confirmation = () => {
  const location = useLocation();
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');

  // Fetching payment status
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const charge_id = params.get('tap_id');
    console.log('Charge ID:', charge_id); // Add this line
    fetchPaymentStatus(charge_id);
  }, [location]);

  const fetchPaymentStatus = async (charge_id) => {
    try {
      const response = await axios.get(`https://api.tap.company/v2/charges/${charge_id}`, {
        headers: {
          'Authorization': 'Bearer sk_test_XKokBfNWv6FIYuTMg5sLPjhJ',
          'accept': 'application/json',
        },
      });

      const data = response.data;

      if (data.status === 'CAPTURED') {
        setPaymentStatus('success');
      } else {
        setPaymentStatus('failure');
        setErrorMessage(data.message || 'Payment failed. Please try again.');
      }
    } catch (error) {
      setPaymentStatus('failure');
      setErrorMessage('An error occurred while checking the payment status.');
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-4">
      {paymentStatus === 'success' ? (
        <div className="text-center bg-white p-6 rounded shadow-md">
          <h1 className="text-2xl font-bold text-green-600">Thank You!</h1>
          <p className="mt-2 text-gray-600">Your payment was successful.</p>
          <button 
            className="mt-4 bg-green-500 text-white py-2 px-4 rounded hover:bg-green-600"
            onClick={() => window.location.href = '/'}
          >
            Go to Home
          </button>
        </div>
      ) : (
        <div className="text-center bg-white p-6 rounded shadow-md">
          <h1 className="text-2xl font-bold text-red-600">Payment Failed</h1>
          <p className="mt-2 text-gray-600">{errorMessage}</p>
          <button
          className="mt-6 px-4 py-2 bg-red-400 text-white rounded hover:bg-red-600"
          onClick={() => window.history.back()}
        >
          Try Again
        </button>
        </div>
      )}
    </div>
  );
};

export default Confirmation;
