import logo from './logo.svg';
// import './App.css';
import './input.css';
import { useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import MainPage from './mainPage';
import LanguageSelector from './languageSelector';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import Cart from './cart';
import CurrencySelector from './currencySelector';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import MAP from './shippingINFO';
import PlaceOrderEmail from './emailplaceorder.js';
import SaudiCheckout from './saudiCheckout.js';

import Checkout from './checkout.js'

import Confirmation from './confirmation.js';
import { GlobalContextProvider } from './context/globalContext.js';
function App() {

  const script = document.createElement("script");
    script.src = "../js/core.min.js";
    script.async = true;
    document.body.appendChild(script);
  const [cartItemsCount, setCartItemsCount] = useState(0);
  return (

    <div className="App">
      <I18nextProvider i18n={i18n}>
        <GlobalContextProvider>
          <BrowserRouter>
            <Routes>
              {/* <Route
            path="/"
            element={<Home  loggedIn={loggedIn} setLoggedIn={setLoggedIn} />}
          /> */}
              <Route path="/" element={<MainPage cartItemsCount={cartItemsCount}  />} />
              <Route path="/cart" element={<Cart cartItemsCount={cartItemsCount} />} />
              <Route path='/languageSelector' element={<LanguageSelector></LanguageSelector>}></Route>
              <Route path='/currencySelector' element={<CurrencySelector></CurrencySelector>}></Route>
              <Route path='/shippingINFO' element={<MAP></MAP>}></Route>
              <Route path='/saudiCheckout' element={<SaudiCheckout></SaudiCheckout>}></Route>
              <Route path='/checkout' element={<Checkout></Checkout>}></Route>
              <Route path='/emailplaceorder' element={<PlaceOrderEmail></PlaceOrderEmail>}></Route>
              <Route path='/confirmation' element={<Confirmation></Confirmation>}></Route>
            </Routes>
          </BrowserRouter>
          {/* <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header> */}
        </GlobalContextProvider>
      </I18nextProvider>
    </div >
  );
}

export default App;
