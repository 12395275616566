import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: {
          "selectState":"Select State ...",
          "selectDistrict":"Select Neighborhood ...",
          "Select delivery method":"Please select a delivery method",
          "SAR" :"SAR",
          "PhoneInput":"Enter phone number with country code 96655XXXXXXXX",
          "Postal Code":"Postal Code",
          "selectCountry":"Select Country ...",
          "selectCity":"Select City ...",
          "Delete":"Delete",
          "VAT": "VAT",
          "Product": "Product",
          "Cart": "Cart",
          "ShortCode":"Short Code",
          "District":"District",
"Current Location":"Fill current location",
          "Quantity": "Quantity",
          "Add to cart": "Add to cart",
          "Our Policies": "Our Policies",
          "Privacy Policy": "Privacy Policy",
          "Terms & Conditions": "Terms & Conditions",
          "Refund Policy": "Refund Policy",
          "Features": "Features",
          "Total Price:":"Total Price",
          "Checkout":"Checkout",
          "items": "items",
"Address1":"Building number, street name, landmark,",
"Address2":"Apt, suite, house number, etc",

          "Contact information":"Contact information",
          "Email address":"Email address",
          "Phone":"Phone",
          "Shipping information":"Shipping information",
          "First name":"First name",
          "Last name":"Last name",
          "Address":"Address",
          "City": "City",
          "Country": "Country",
          "United States": "United States",
          "Saudi Arabia": "Saudi Arabia",
          "State / Province": "State / Province",
          "Delivery Method": "Delivery Method",
          "Payment": "Payment",
          "Payment type": "Payment type", 
          "Card number": "Card number",
          "Name on card": "Name on card",
          "Expiration date (MM/YY)": "Expiration date (MM/YY)",
          "Order Summary": "Order Summary",
          "Subtotal": "Subtotal",
          "Shipping": "Shipping",
          "Total": "Total",
          "Confirm order": "Confirm order",
          "Location Details": "Location Details",
          "Lat":"Lat",
          "Lng":"Lng",
          "Save":"Save",
          "Current Location":"Current Location",
// "First name is required.":"First name is required.",
// "Last name is required.":"Last name is required.",
// "Email is required.":"Email is required.",
// "Phone number is required.":"Phone number is required.",
// "Address Line 1 is empty.": "Address Line 1 is empty.",
// "Address Line 2 is empty.":"Address Line 2 is empty.",
// "City is empty.":"City is empty.",
// "country is empty.":"country is empty.",
// "Post code is empty.":"Post code is empty.",

          "At Company A, we value your privacy.": "At Company A, we value your privacy.",
          "This Privacy Policy explains how we collect, use, and protect your personal information.": "This Privacy Policy explains how we collect, use, and protect your personal information.",
          "1. Information We Collect": "1. Information We Collect",
          "1.1. Personal Information| Name, email address, phone number ,IP Address, Geographical location upon user approval.": "1.1. Personal Information| Name, email address, phone number ,IP Address, Geographical location upon user approval.",
          "1.2. Transaction Information| Details about your purchases.": "1.2. Transaction Information| Details about your purchases.",
          "1.3. Technical Information| IP address, browser type, etc.": "1.3. Technical Information| IP address, browser type, etc.",
          "2. How We Use Your Information": "2. How We Use Your Information",
          "2.1. To process and manage your orders.": "2.1. To process and manage your orders.",
          "2.2. To communicate with you about your orders and promotions.": "2.2. To communicate with you about your orders and promotions.",
          "2.3. To improve our website and services.": "2.3. To improve our website and services.",
          "3. How We Protect Your Information": "3. How We Protect Your Information",
          "3.1. We implement security measures to protect your data.": "3.1. We implement security measures to protect your data.",
          "3.2. We do not sell or rent your personal information to third parties.": "3.2. We do not sell or rent your personal information to third parties.",
          "4. Cookies": "4. Cookies",
          "4.1. We use cookies to enhance your experience on our website.": "4.1. We use cookies to enhance your experience on our website.",
          "4.2. You can choose to disable cookies in your browser settings.": "4.2. You can choose to disable cookies in your browser settings.",
          "5. Third-Party Links": "5. Third-Party Links",
          "5.1. Our website may contain links to other sites. We are not responsible for their privacy practices.": "5.1. Our website may contain links to other sites. We are not responsible for their privacy practices.",
          "6. Your Rights": "6. Your Rights",
          "6.1. You have the right to access and update your personal information.": "6.1. You have the right to access and update your personal information.",
          "6.2. You may request to delete your personal information by contacting our support team at Support@ExcellentB.sa": "6.2. You may request to delete your personal information by contacting our support team at Support@ExcellentB.sa",
          "7. Changes to This Policy": "7. Changes to This Policy",
          "7.1. We may update this Privacy Policy from time to time. Any changes will be posted on this page.": "7.1. We may update this Privacy Policy from time to time. Any changes will be posted on this page.",
          "Contact Us": "Contact Us",
          "For any questions about this Privacy Policy, please contact us at Support@ExcellentB.sa": "For any questions about this Privacy Policy, please contact us at Support@ExcellentB.sa",
          "Version: 1.01 Release Date: 2024-08-24": "Version: 1.01 Release Date: 2024-08-24",

          "Welcome to A Website!": "Welcome to A Website!",
          "By using our website and services, you agree to the following terms and conditions. Please read them carefully.": "By using our website and services, you agree to the following terms and conditions. Please read them carefully.",
          "1. General": "1. General",
          "1.1. These Terms & Conditions govern your use of our website and services.": "1.1. These Terms & Conditions govern your use of our website and services.",
          "1.2. We reserve the right to update these terms at any time. Your continued use of the site constitutes acceptance of any changes.": "1.2. We reserve the right to update these terms at any time. Your continued use of the site constitutes acceptance of any changes.",
          "2. User Responsibilities": "2. User Responsibilities",
          "2.1. You must be at least 18 years old to use our services.": "2.1. You must be at least 18 years old to use our services.",
          "2.2. You are responsible for maintaining the confidentiality of your account and password and for all activities that occur under your account.": "2.2. You are responsible for maintaining the confidentiality of your account and password and for all activities that occur under your account.",
          "3. Products and Services": "3. Products and Services",
          "3.1. We strive to provide accurate product descriptions and images. However, we do not warrant that product descriptions are accurate, complete, reliable, or error-free.": "3.1. We strive to provide accurate product descriptions and images. However, we do not warrant that product descriptions are accurate, complete, reliable, or error-free.",
          "3.2. Prices and availability of products are subject to change without notice.": "3.2. Prices and availability of products are subject to change without notice.",
          "4. Payments": "4. Payments",
          "4.1. Payments must be made through the payment methods we accept.": "4.1. Payments must be made through the payment methods we accept.",
          "4.2. All transactions are processed securely.": "4.2. All transactions are processed securely.",
          "5. Shipping and Delivery": "5. Shipping and Delivery",
          "5.1. We will make every effort to deliver your order within the estimated delivery time.": "5.1. We will make every effort to deliver your order within the estimated delivery time.",
          "5.2. We are not responsible for delays caused by external factors.": "5.2. We are not responsible for delays caused by external factors.",
          "6. Returns and Refunds": "6. Returns and Refunds",
          "6.1. Please refer to our Refund Policy for information on returns and refunds.": "6.1. Please refer to our Refund Policy for information on returns and refunds.",
          "7. Limitation of Liability": "7. Limitation of Liability",
          "7.1. We are not liable for any indirect, incidental, or consequential damages arising from the use of our website or services.": "7.1. We are not liable for any indirect, incidental, or consequential damages arising from the use of our website or services.",
          "8. Governing Law": "8. Governing Law",
          "8.1. These terms are governed by the laws of Saudi Arabia.": "8.1. These terms are governed by the laws of Saudi Arabia.",
          "If you have any questions about these Terms & Conditions, please contact us at Support@ExcellentB.sa": "If you have any questions about these Terms & Conditions, please contact us at Support@ExcellentB.sa",
          "Version: 1.01": "Version: 1.01",

          "At A company, we want you to be satisfied with your purchase.": "At A company, we want you to be satisfied with your purchase.",
          "1. Eligibility for Refunds": "1. Eligibility for Refunds",
          "1.1. You may request a refund if the product is defective, not as described, or if you receive the wrong item.": "1.1. You may request a refund if the product is defective, not as described, or if you receive the wrong item.",
          "1.2. Refunds are only available within [30] days of the purchase date.": "1.2. Refunds are only available within [30] days of the purchase date.",
          "2. How to Request a Refund": "2. How to Request a Refund",
          "2.1. Contact our customer service team at Support@ExcellentB.sa to initiate the refund process.": "2.1. Contact our customer service team at Support@ExcellentB.sa to initiate the refund process.",
          "2.2. Provide your order number and reason for the refund request.": "2.2. Provide your order number and reason for the refund request.",
          "3. Refund Process": "3. Refund Process",
          "3.1. Once your refund request is approved, we will process it within [7-10] business days.": "3.1. Once your refund request is approved, we will process it within [7-10] business days.",
          "3.2. Refunds will be issued to the original payment method.": "3.2. Refunds will be issued to the original payment method.",
          "4. Non-Refundable Items": "4. Non-Refundable Items",
          "4.1. Certain items, such as [customized products, perishable goods], are not eligible for refunds.": "4.1. Certain items, such as [customized products, perishable goods], are not eligible for refunds.",
          "5. Shipping Costs": "5. Shipping Costs",
          "5.1. Shipping costs are non-refundable.": "5.1. Shipping costs are non-refundable.",
          "6. Exchanges": "6. Exchanges",
          "6.1. If you wish to exchange a product, please follow our return process and place a new order.": "6.1. If you wish to exchange a product, please follow our return process and place a new order.",
          "For any questions about our Refund Policy, please contact us at support@ExcellentB.sa": "For any questions about our Refund Policy, please contact us at support@ExcellentB.sa",

        }
      },
      ar: {
        translation: {
          "selectDistrict":"اختر الحي...",
         "Shipping Information": "معلومات الشحن",
          "selectState" :"اختر المنطقة ..",
          "SAR" : "ريال",
          "Select delivery method":"فضلاً اختر طريقة الشحن ",
          "PhoneInput":"ادخل رقم الجوال مع الرمز الدولي 966554XXXXXXXX",
          "Postal Code":"الرمز البريدي",
          "selectCountry":"احتر الدولة ....",
          "selectCity":"اختر المدينة...",
          "Delete":"حذف",
          "VAT":"الضريبة",
          "Enter Address": "املئ العنوان",
          "ShortCode": "العنوان القصير",
          "Product": "المنتج",
          "Cart": "السلة",
          "Current Location":'استخدم الموقع الحالي',
          "Quantity": "الكمية",
          "Add to cart": "إضافة إلى السلة",
          "Our Policies": "سياستنا",
          "Privacy Policy": "سياسة الخصوصية",
          "Terms & Conditions": "الشروط والأحكام",
          "Refund Policy": "سياسة الاسترداد",
          "Features": "المميزات",
          "Total Price:":"السعر الاجمالي",
          "Checkout":"الدفع",
          "items": "منتج",

          
          "Contact information": "معلومات الاتصال",
          "Email address": "عنوان البريد الإلكتروني",
          "Phone": "الهاتف",
          "Shipping Iformation": "معلومات الشحن",
          "First name": "الاسم الأول",
          "Last name": "اسم العائلة",
          "Address": "العنوان",
          "Address1": "رقم المنزل ، اسم الشارع",
          "Address2": "رقم الشقة ، البوابة ، معلومات اخرى",
          "City": "المدينة",
          "Country": "الدولة",
          "United States": "الولايات المتحدة",
          "Saudi Arabia": "المملكة العربية السعودية",
          "State / Province": "الولاية / المقاطعة",
          "Delivery Method": "طريقة التوصيل",
          "Payment": "الدفع",
          "Payment type": "نوع الدفع",
          "Card number": "رقم البطاقة",
          "Name on card": "اسم على البطاقة",
          "Expiration date (MM/YY)": "تاريخ انتهاء الصلاحية (شهر/سنة)",
          "Order Summary": "ملخص الطلب",
          "Subtotal": "المجموع الفرعي",
          "Shipping": "الشحن",
          "Total": "الإجمالي",
          "Confirm order": "تأكيد الطلب",
"Location Details": "تفاصيل الموقع",
          "Lat":"خط العرض",
          "Lng":"خط الطول",
          "Save":"حفظ",
          "Current Location":"الموقع الحالي",
// "First name is required.":"الاسم الاول مطلوب ",
// "Last name is required.":"الاسم الاخير مطلوب ",
// "Email is required.":"الايميل مطلوب ",
// "Phone number is required.":"رقم الهاتف مطلوب",
// "Address Line 1 is empty.": "العنوان فارغ",
// "Address Line 2 is empty.":"العنوان2 فارغ",
// "City is empty.":" الرجاء ادخال المدينة",
// "country is empty.":"الرجاء ادخال الدولة",
// "Post code is empty.":"الرجاء ادخال الرمز البريدي",
          "At Company A, we value your privacy.": "في شركة أ، نحن نقدر خصوصيتك.",
          "This Privacy Policy explains how we collect, use, and protect your personal information.": "توضح سياسة الخصوصية هذه كيفية جمعنا واستخدامنا وحمايتنا لمعلوماتك الشخصية.",
          "1. Information We Collect": "1. المعلومات التي نجمعها",
          "1.1. Personal Information| Name, email address, phone number ,IP Address, Geographical location upon user approval.": "1.1. المعلومات الشخصية| الاسم، البريد الإلكتروني، رقم الهاتف، عنوان IP، الموقع الجغرافي بموافقة المستخدم.",
          "1.2. Transaction Information| Details about your purchases.": "1.2. معلومات المعاملات| تفاصيل عمليات الشراء الخاصة بك.",
          "1.3. Technical Information| IP address, browser type, etc.": "1.3. المعلومات التقنية| عنوان IP، نوع المتصفح، إلخ.",
          "2. How We Use Your Information": "2. كيفية استخدامنا لمعلوماتك",
          "2.1. To process and manage your orders.": "2.1. لمعالجة وإدارة طلباتك.",
          "2.2. To communicate with you about your orders and promotions.": "2.2. للتواصل معك بشأن طلباتك والعروض الترويجية.",
          "2.3. To improve our website and services.": "2.3. لتحسين موقعنا الإلكتروني وخدماتنا.",
          "3. How We Protect Your Information": "3. كيفية حماية معلوماتك",
          "3.1. We implement security measures to protect your data.": "3.1. نحن نتخذ تدابير أمنية لحماية بياناتك.",
          "3.2. We do not sell or rent your personal information to third parties.": "3.2. نحن لا نبيع أو نؤجر معلوماتك الشخصية لأطراف ثالثة.",
          "4. Cookies": "4. الكوكيز",
          "4.1. We use cookies to enhance your experience on our website.": "4.1. نستخدم الكوكيز لتعزيز تجربتك على موقعنا.",
          "4.2. You can choose to disable cookies in your browser settings.": "4.2. يمكنك اختيار تعطيل الكوكيز في إعدادات متصفحك.",
          "5. Third-Party Links": "5. الروابط إلى أطراف ثالثة",
          "5.1. Our website may contain links to other sites. We are not responsible for their privacy practices.": "5.1. قد يحتوي موقعنا على روابط إلى مواقع أخرى. نحن غير مسؤولين عن ممارسات الخصوصية الخاصة بهم.",
          "6. Your Rights": "6. حقوقك",
          "6.1. You have the right to access and update your personal information.": "6.1. لديك الحق في الوصول إلى معلوماتك الشخصية وتحديثها.",
          "6.2. You may request to delete your personal information by contacting our support team at Support@ExcellentB.sa": "6.2. يمكنك طلب حذف معلوماتك الشخصية بالتواصل عبر البريد الإلكتروني: Support@ExcellentB.sa.",
          "7. Changes to This Policy": "7. تغييرات على هذه السياسة",
          "7.1. We may update this Privacy Policy from time to time. Any changes will be posted on this page.": "7.1. قد نقوم بتحديث سياسة الخصوصية هذه من وقت لآخر. سيتم نشر أي تغييرات على هذه الصفحة.",
          "Contact Us": "اتصل بنا",
          "For any questions about this Privacy Policy, please contact us at Support@ExcellentB.sa": "لأي أسئلة حول سياسة الخصوصية هذه، يرجى الاتصال بنا على Support@ExcellentB.sa.",
          "Version: 1.01 Release Date: 2024-08-24": "الإصدار: 1.01 تاريخ الإصدار: 2024-08-24",
          
          "Welcome to A Website!": "مرحبًا بك في موقع أ",
          "By using our website and services, you agree to the following terms and conditions. Please read them carefully.": "باستخدامك لموقعنا الإلكتروني وخدماتنا، فإنك توافق على الشروط والأحكام التالية. يرجى قراءتها بعناية.",
          "1. General": "1. عام",
          "1.1. These Terms & Conditions govern your use of our website and services.": "1.1. تحكم هذه الشروط والأحكام استخدامك لموقعنا الإلكتروني وخدماتنا.",
          "1.2. We reserve the right to update these terms at any time. Your continued use of the site constitutes acceptance of any changes.": "1.2. نحتفظ بالحق في تحديث هذه الشروط في أي وقت. استمرارك في استخدام الموقع يُعتبر قبولاً لأي تغييرات.",
          "2. User Responsibilities": "2. مسؤوليات المستخدم",
          "2.1. You must be at least 18 years old to use our services.": "2.1. يجب أن يكون عمرك ١٨ عامًا على الأقل لاستخدام خدماتنا.",
          "2.2. You are responsible for maintaining the confidentiality of your account and password and for all activities that occur under your account.": "2.2. أنت مسؤول عن الحفاظ على سرية حسابك وكلمة المرور الخاصة بك، وجميع الأنشطة التي تحدث تحت حسابك.",
          "3. Products and Services": "3. المنتجات والخدمات",
          "3.1. We strive to provide accurate product descriptions and images. However, we do not warrant that product descriptions are accurate, complete, reliable, or error-free.": "3.1. نسعى لتوفير أوصاف وصور دقيقة للمنتجات. ومع ذلك، لا نضمن أن تكون أوصاف المنتجات دقيقة أو كاملة أو موثوقة أو خالية من الأخطاء.",
          "3.2. Prices and availability of products are subject to change without notice.": "3.2. الأسعار وتوافر المنتجات عرضة للتغيير دون إشعار.",
          "4. Payments": "4. المدفوعات",
          "4.1. Payments must be made through the payment methods we accept.": "4.1. يجب إجراء المدفوعات من خلال طرق الدفع التي نقبلها.",
          "4.2. All transactions are processed securely.": "4.2. جميع المعاملات تتم معالجتها بأمان.",
          "5. Shipping and Delivery": "5. الشحن والتوصيل",
          "5.1. We will make every effort to deliver your order within the estimated delivery time.": "5.1. سنبذل قصارى جهدنا لتوصيل طلبك في الوقت المقدر للتسليم.",
          "5.2. We are not responsible for delays caused by external factors.": "5.2. نحن غير مسؤولين عن التأخيرات التي تسببها عوامل خارجية.",
          "6. Returns and Refunds": "6. الإرجاع والاسترداد",
          "6.1. Please refer to our Refund Policy for information on returns and refunds.": "6.1. يرجى الرجوع إلى سياسة الاسترداد لدينا للحصول على معلومات حول الإرجاع والاسترداد.",
          "7. Limitation of Liability": "7. تحديد المسؤولية",
          "7.1. We are not liable for any indirect, incidental, or consequential damages arising from the use of our website or services.": "7.1. نحن غير مسؤولين عن أي أضرار غير مباشرة أو عرضية أو تبعية ناتجة عن استخدام موقعنا أو خدماتنا.",
          "8. Governing Law": "8. القانون المعمول به",
          "8.1. These terms are governed by the laws of Saudi Arabia.": "8.1. تحكم هذه الشروط بقوانين المملكة العربية السعودية.",
          "If you have any questions about these Terms & Conditions, please contact us at Support@ExcellentB.sa": "إذا كان لديك أي أسئلة حول هذه الشروط والأحكام، يرجى الاتصال بنا على Support@excellentb.sa",
          "Version: 1.01": "الإصدار 1.01",

          "At A company, we want you to be satisfied with your purchase.": "في أ، نهدف إلى رضاك الكامل عن مشترياتك.",
          "1. Eligibility for Refunds": "1. أهلية الاسترداد",
          "1.1. You may request a refund if the product is defective, not as described, or if you receive the wrong item.": "1.1 يمكنك طلب استرداد إذا كان المنتج معيبًا، أو غير مطابق للوصف، أو إذا استلمت منتجًا خاطئًا.",
          "1.2. Refunds are only available within [30] days of the purchase date.": "1.2 الاستردادات متاحة فقط خلال [30] يومًا من تاريخ الشراء.",
          "2. How to Request a Refund": "2. كيفية طلب استرداد",
          "2.1. Contact our customer service team at Support@ExcellentB.sa to initiate the refund process.": "2.1 اتصل بفريق خدمة العملاء لدينا على Support@ExcellentB.sa لبدء عملية الاسترداد.",
          "2.2. Provide your order number and reason for the refund request.": "2.2 قدم رقم الطلب وسبب طلب الاسترداد.",
          "3. Refund Process": "3. عملية الاسترداد",
          "3.1. Once your refund request is approved, we will process it within [7-10] business days.": "3.1 بمجرد الموافقة على طلب الاسترداد، سنقوم بمعالجته خلال [7-10] أيام عمل.",
          "3.2. Refunds will be issued to the original payment method.": "3.2 سيتم إصدار الاسترداد إلى طريقة الدفع الأصلية.",
          "4. Non-Refundable Items": "4. العناصر غير القابلة للاسترداد",
          "4.1. Certain items, such as [customized products, perishable goods], are not eligible for refunds.": "4.1 بعض العناصر، مثل [المنتجات المخصصة، البضائع القابلة للتلف]، لا يمكن استردادها.",
          "5. Shipping Costs": "5. تكاليف الشحن",
          "5.1. Shipping costs are non-refundable.": "5.1 تكاليف الشحن غير قابلة للاسترداد.",
          "6. Exchanges": "6. التبديلات",
          "6.1. If you wish to exchange a product, please follow our return process and place a new order.": "6.1 إذا كنت ترغب في تبديل منتج، يرجى اتباع عملية الإرجاع الخاصة بنا وإجراء طلب جديد.",
          "For any questions about our Refund Policy, please contact us at support@ExcellentB.sa": "لأي استفسارات حول سياسة الاسترداد لدينا، يرجى الاتصال بنا على Support@ExcellentB.sa.",
        }
      }
    },
    lng: 'en', // default language
    fallbackLng: 'en', // fallback language
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });
  
// Set the initial direction based on the default language
document.documentElement.dir = i18n.language === 'ar' ? 'rtl' : 'ltr';

export default i18n;