// CheckoutForm.js
import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { RadioGroup, Radio } from '@headlessui/react';
import { CheckCircleIcon, ShoppingBagIcon, Bars3Icon, XMarkIcon } from '@heroicons/react/20/solid';
import LanguageSelector from './languageSelector';
import CurrencySelector from './currencySelector';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
// import { TrashIcon } from '@heroicons/react/24/outline';
 import { Disclosure,   } from '@headlessui/react';


 const API_KEY = 'AIzaSyBiFSvbtlW9ovAK0RG1VQvQVLkq2z_y1WY';

 
const deliveryMethods = [
    { id: 1, title: 'Standard', turnaround: '4–10 business days', price: '5.00' },
    { id: 2, title: 'Express', turnaround: '2–5 business days', price: '16.00' },
  ];
const Cart = (props) => {
  const location = useLocation();
  const cartItems = location.state?.cartItems || [];


  
  const navigate = useNavigate();

  // State management
 
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [addressLine1, setAddressLine1] = useState('');
  const [addressLine2, setAddressLine2] = useState('');
  const [country, setCountry] = useState('');
  const [city, setCity] = useState('');
  const [district, setDistrict] = useState('');
  const [postCode, setPostCode] = useState('');
  const [showError, setShowError] = useState('');
  const [errors, setErrors] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    addressLine1: '',
    addressLine2:'',
    country:'',
    city: '',
    district:'', 
    postCode: '',
  });
  const [message, setMessage] = useState('');

  const [selectedDeliveryMethod, setSelectedDeliveryMethod] = useState(deliveryMethods[0]);
  const [selectedCurrency, setSelectedCurrency] = useState('USD');
  const [product, setProduct] = useState([]);
  const [cartItemsCount, setCartItemsCount] = useState(cartItems.length);
  const [isRtl, setIsRtl] = useState(false);

  const { t, i18n } = useTranslation();
  useEffect(() => {
    // Update cart items count based on the cartItems array
    setCartItemsCount(cartItems.reduce((total, item) => total + item.quantity, 0));
  }, [cartItems]);
  
  useEffect(() => {
    axios.get('https://spotsaletest.azurewebsites.net/sampleVar1')
      .then(response => {
        const data = response.data;
        const language = i18n.language === 'ar' ? 'Ar' : 'En';
        
        // Fetch product details based on the selected currency
        const fetchedProducts = cartItems.map(item => ({
            id: item.id,
            title: data[language].Product_Title,
            price: data.Prices[selectedCurrency] , // Calculate total price for this item
            href: '#',
            imageSrc: data.Images.Main,
            imageAlt: 'Main product image',
            quantity: item.quantity
        }));

        setProduct(fetchedProducts);
      })
      .catch(error => console.error('Error fetching product data:', error));
  }, [selectedCurrency, cartItems, i18n.language]);



const calculateTotal = () => {
    const subtotal = product.reduce((sum, item) => sum + (item.price * item.quantity), 0);
    const shipping = selectedDeliveryMethod ? parseFloat(selectedDeliveryMethod.price) : 0;
    const total = subtotal + shipping;
    return { subtotal, total };
  };

  useEffect(() => {
    document.documentElement.dir = i18n.language === 'ar' ? 'rtl' : 'ltr';
    setIsRtl(i18n.language === 'ar');
  }, [i18n.language]);

  const handleLanguageChange = (lang) => {
    i18n.changeLanguage(lang);
  };

  const handleCurrencyChange = (currency) => {
    setSelectedCurrency(currency);
  };
  
 // Handle country selection
//  const handleCountryChange = (event) => {
//   const country = event.target.value;
//   setSelectedCountry(country);
//   setIsSaudiArabia(country === 'Saudi Arabia');
// };
// const handlePhoneChange = (event) => {
//   setPhone(event.target.value);
// };
// const handleDistrictChange = (event) => {
//   setDistrict(event.target.value);
// };
const handleRequestLocation = async () => {
  navigator.geolocation.getCurrentPosition(async position => {
    const { latitude, longitude } = position.coords;

    try {
      // Call the Geocoding API
      const geocodeResponse = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json`, {
        params: {
          latlng: `${latitude},${longitude}`,
          key: API_KEY,
        },
      });

      if (geocodeResponse.data.results.length > 0) {
        const addressComponents = geocodeResponse.data.results[0].address_components;

        // Extract necessary details
        addressComponents.forEach(component => {
          if (component.types.includes('country')) {
            setCountry(component.long_name);
          }
          if (component.types.includes('locality')) {
            setCity(component.long_name);
          }
          if (component.types.includes('administrative_area_level_2')) {
            setDistrict(component.long_name);
          }
          if (component.types.includes('postal_code')) {
            setPostCode(component.long_name);
          }
        });
        setAddressLine2(geocodeResponse.data.results[0].formatted_address);
      } else {
        setShowError('No address found for the current location.');
      }
    } catch (error) {
      console.error('Error retrieving address:', error);
      setShowError('Could not retrieve address. Please try again.');
    }
  });
};

const handleAddressSubmit = async () => {
  // Prepare the payload for submission
  const payload = {
    Ocode: localStorage.getItem('Order_Code'),
    first_name: firstName,
    last_name: lastName,
    email,
    phone,
    address1: addressLine1,
    address2: addressLine2,
     country,
    city,
    district,
    post_code: postCode,
  };

  
 // Validate inputs
 let formIsValid = true;
 if (!firstName) {
   setErrors(prev => ({ ...prev, firstName: 'First name is required.' }));
   formIsValid = false;
 }
 if (!lastName) {
   setErrors(prev => ({ ...prev, lastName: 'Last name is required.' }));
   formIsValid = false;
 }
 if (!email) {
   setErrors(prev => ({ ...prev, email: 'Email is required.' }));
   formIsValid = false;
 }
 if (!phone) {
   setErrors(prev => ({ ...prev, phone: 'Phone number is required.' }));
   formIsValid = false;
 }
 if (!addressLine1) {
   setErrors(prev => ({ ...prev, addressLine1: 'Address Line 1 is empty.' }));
   formIsValid = false;
 }
 if (!addressLine2) {
  setErrors(prev => ({ ...prev, addressLine2: 'Address Line 2 is empty.' }));
  formIsValid = false;
}
 if (!city) {
   setErrors(prev => ({ ...prev, city: 'City is empty.' }));
   formIsValid = false;
 }
 if (!country) {
  setErrors(prev => ({ ...prev, country: 'country is empty.' }));
  formIsValid = false;
}
 if (!postCode) {
   setErrors(prev => ({ ...prev, postCode: 'Post code is empty.' }));
   formIsValid = false;
 }

 if (!formIsValid) return; // Stop submission if validation fai
  try {
    // Send the address to your server
    const ret = await axios.post('https://liven.today/api/Orders/Address_Submit', payload);
    
    console.log(ret.data);
    if (ret.data.length === 0) {
      setShowError('Order not sent or invalid data');
    } else {
      setMessage('Location saved successfully.');
      
      const redirectURL = ret.data.toURL; // Assuming `toURL` is included in the response
    window.location.href = redirectURL; // Redirecting the user 
 
    }
    
  } catch (error) {
    console.error('Error submitting address:', error);
    setShowError('Could not submit address. Please try again.');
  }
};


  return (
    <div className="bg-gray-50">
<header className="fixed top-0 left-0 right-0 bg-white z-50 shadow-md">
  <Disclosure as="nav" className="bg-white">
    <div className="relative bg-white">
      <div className="flex h-16 items-center justify-between">  
        <div  className={`absolute inset-y-0 ${isRtl ? 'right-0' : 'left-0'} flex items-center sm:hidden `}>
          {/* Mobile menu button */}
          <Disclosure.Button className="inline-flex items-center justify-center p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
            <XMarkIcon className="hidden h-6 w-6" aria-hidden="true" />
          </Disclosure.Button>
        </div>
        <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start text-left">
          <div className={`ml-4 flex flex-shrink-0 items-center ${isRtl ? 'mr-2' : ''}`}>
            <a href="https://tailblocks.cc" target="_blank" rel="noopener noreferrer">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                className="w-10 h-10 text-white p-2 bg-indigo-500 rounded-full"
                viewBox="0 0 24 24"
              >
                <path d="M12 2L2 7l10 5 10-5-10-5zM2 17l10 5 10-5M2 12l10 5 10-5"></path>
              </svg>
            </a>
            <span className={`ml-2 text-xl text-gray ${isRtl ? 'mr-2' : ''}`}>Excellent Brands</span>
          </div>
          <div className="hidden sm:ml-6 sm:flex sm:space-x-4">
            <LanguageSelector
              onLanguageChange={handleLanguageChange}
              className="ml-4 inline-flex w-32 items-center justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            />
            <CurrencySelector
              selectedCurrency={selectedCurrency}
              onCurrencyChange={handleCurrencyChange}
              className="ml-4 inline-flex w-32 items-center justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            />
          </div>
        </div>
        {/* Cart button positioned to the right */}
        <div className={`absolute ${isRtl ? 'left-0' : 'right-0'} top-1/2 transform -translate-y-1/2 mr-4 ml-4`}>
  <button
    onClick={() => navigate('/cart', { state: { cartItems } })}
    className="inline-flex items-center justify-center"
  >
    <ShoppingBagIcon className="h-5 w-5 mr-2" />
    {cartItemsCount}
  </button>
</div>
      </div>
    </div>
    <Disclosure.Panel className="sm:hidden">
      <div className="px-20 pb-4 flex flex-row">
        <LanguageSelector
          onLanguageChange={handleLanguageChange}
          className="ml-4 inline-flex w-32 items-center justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        />
        <CurrencySelector
          selectedCurrency={selectedCurrency}
          onCurrencyChange={handleCurrencyChange}
          className="ml-4 inline-flex w-32 items-center justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        />
        
      </div>
    </Disclosure.Panel>
  </Disclosure>
</header>
    <div className="mx-auto max-w-2xl px-4 pb-24 pt-16 sm:px-6 lg:max-w-7xl lg:px-8 ml-2"> 
  <h2 className="sr-only">Checkout</h2>

  <div className="lg:grid lg:grid-cols-2 lg:gap-x-12 xl:gap-x-16">
    <div>
      <div>
        <h2 className="text-lg font-medium text-gray-900">{t('Contact information')}</h2>
        <div className="mt-4">
          <label htmlFor="email-address" className="block text-sm font-medium text-gray-700">
            {t('Email address')}
          </label>
          <div className="mt-1">
          <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email" className="mt-1 block w-full border border-gray-300 rounded-lg p-2" required />
          {errors.email && <p className="text-red-600">{errors.email}</p>}
          </div>
        </div>
        <div className="sm:col-span-2 mt-4">
          <label htmlFor="phone" className="block text-sm font-medium text-gray-700">
            {t('Phone')}
          </label>
          <div className="mt-1">
          <input type="tel" value={phone} onChange={(e) => setPhone(e.target.value)} placeholder="Phone" className="mt-1 block w-full border border-gray-300 rounded-lg p-2" required />
          {errors.phone && <p className="text-red-600">{errors.phone}</p>}
          </div>
        </div>
      </div>
      <div className="mt-10 border-t border-gray-200 pt-10">
      <fieldset>
        <legend className="text-lg font-medium">{t('Delivery Method')}</legend>
        <RadioGroup value={selectedDeliveryMethod} onChange={setSelectedDeliveryMethod} className="mt-4">
          <div className="space-y-4">
            {deliveryMethods.map((method) => (
              <RadioGroup.Option
                key={method.id}
                value={method}
                className={({ active, checked }) =>
                  `relative flex cursor-pointer rounded-lg border p-4 focus:outline-none ${
                    active ? 'ring-2 ring-indigo-500' : ''
                  } ${checked ? 'bg-indigo-50 border-indigo-600' : 'border-gray-200'}`
                }
              >
                {({ active, checked }) => (
                  <>
                    <div className="flex-shrink-0">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        className={`h-6 w-6 ${checked ? 'text-indigo-600' : 'text-gray-400'}`}
                        viewBox="0 0 24 24"
                      >
                        <path d="M5 12l5 5L20 7"></path>
                      </svg>
                    </div>
                    <div className="ml-3 flex-1">
                      <p className="text-sm font-medium text-gray-900">{method.title}</p>
                      <p className="text-sm text-gray-500">{method.turnaround}</p>
                    </div>
                    <div className="ml-4 flex-shrink-0 text-sm font-medium text-gray-900">
                      ${method.price}
                    </div>
                  </>
                )}
              </RadioGroup.Option>
            ))}
          </div>
        </RadioGroup>
      </fieldset>
    </div> 

      <div className="mt-10 border-t border-gray-200 pt-10">
        <h2 className="text-lg font-medium text-gray-900">{t('Shipping information')}</h2>
        {/* <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
          <label htmlFor="country" className="block text-sm font-medium text-gray-700">
            {t('Country')}
          </label>
          <select onChange={handleCountryChange} className="block w-full rounded-md border-gray-300">
            <option value="United States">{t('United States')}</option>
            <option value="Saudi Arabia">{t('Saudi Arabia')}</option>
          </select>
          {isSaudiArabia ? (
            <>
            <Map></Map>
               {/* {navigate('/saudiCheckout')} */}
          
            <>
              <div>
                <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                  {t('First name')}
                </label>
                <input type="text" value={firstName} onChange={(e) => setFirstName(e.target.value)} placeholder="First Name" className="mt-1 block w-full border border-gray-300 rounded-lg p-2" required />
                {errors.firstName && <p className="text-red-600">{errors.firstName}</p>}
              </div>
              <div>
                <label htmlFor="last-name" className="block text-sm font-medium text-gray-700">
                  {t('Last name')}
                </label>
                <input type="text" value={lastName} onChange={(e) => setLastName(e.target.value)} placeholder="Last Name" className="mt-1 block w-full border border-gray-300 rounded-lg p-2" required />
                {errors.lastName && <p className="text-red-600">{errors.lastName}</p>}
              </div>
              <div>

                <label htmlFor="city" className="block text-sm font-medium text-gray-700">
                  {t('City')}
                </label>
                <input type="text" value={city} onChange={(e) => setCity(e.target.value)} className="mt-1 block w-full border border-gray-300 rounded-lg p-2" placeholder="City" />
                {errors.city && <p className="text-red-600">{errors.city}</p>}
              </div>

              <div>
                <label htmlFor="country" className="block text-sm font-medium text-gray-700">
                  {t('country')}
                </label>
                {errors.country && <p className="text-red-600">{errors.country}</p>}
                <input type="text" value={country} onChange={(e) => setCountry(e.target.value)} className="mt-1 block w-full border border-gray-300 rounded-lg p-2" placeholder="Country" />
              </div>

              <div className="sm:col-span-2">
                <label htmlFor="address" className="block text-sm font-medium text-gray-700">
                  {t('Address Line1')}
                </label>
                <input type="text" value={addressLine1} onChange={(e) => setAddressLine1(e.target.value)} className="mt-1 block w-full border border-gray-300 rounded-lg p-2" placeholder="Address Line 1" />
                {errors.addressLine1 && <p className="text-red-600">{errors.addressLine1}</p>}
           
              </div>
              <div className="sm:col-span-2">
                <label htmlFor="address" className="block text-sm font-medium text-gray-700">
                  {t('Address Line2')}
                </label>
                <input type="text" value={addressLine2} onChange={(e) => setAddressLine2(e.target.value)} className="mt-1 block w-full border border-gray-300 rounded-lg p-2" placeholder="Address Line 2" />
                {errors.addressLine2 && <p className="text-red-600">{errors.addressLine2}</p>}
   
              </div>
              
              <div>
                <label htmlFor="postCode" className="block text-sm font-medium text-gray-700">
                  {t('postCode')}
                </label>
                <input type="text" value={postCode} onChange={(e) => setPostCode(e.target.value)} className="mt-1 block w-full border border-gray-300 rounded-lg p-2" placeholder="Post Code" />
                {errors.postCode && <p className="text-red-600">{errors.postCode}</p>}
              </div>
              {showError && <p className="text-red-600">{showError}</p>}
        <div className="mt-6">
          {/* <button
            onClick={handleAddressSubmit}
            type="submit"
            className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600"
          >
            Save Location
          </button> */}
          <button
            type="button"
            onClick={handleRequestLocation}
            className="ml-4 bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600"
          >
            Current Location
          </button>
        </div>
        {showError && <p className="text-red-600">{showError}</p>}
        {message && <p className="mt-4 text-green-600">{message}</p>}
    
            </>
       
       
      </div>
    

    
    </div>
    
    {/* Order summary */}
    <div className="mt-10 lg:mt-0">
      <h2 className="text-lg font-medium text-gray-900">{t('Order Summary')}</h2>
      <div className="mt-4 rounded-lg border border-gray-200 bg-white shadow-sm">
        <h3 className="sr-only">Items in your cart</h3>
        <ul className="divide-y divide-gray-200">
          {product.map((item) => (
            <li key={item.id} className="flex py-6">
              <div className={`h-24 w-24 flex-shrink-0 overflow-hidden rounded-md border border-gray-200 ml-2 ${isRtl ? 'mr-2 ' : '' }`}>
                <img
                  src={item.imageSrc}
                  alt={item.imageAlt}
                  className="h-full w-full object-cover object-center"
                />
              </div>
              <div className="ml-4 flex flex-1 flex-col">
                <div>
                  <div className="flex justify-between text-base font-medium text-gray-900">
                    <h3>
                      <a href={item.href}>{item.title}</a>
                    </h3>
                    <p className="mr-2"> {selectedCurrency} {item.price} * {item.quantity}</p>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
        <dl className="space-y-6 border-t border-gray-200 px-4 py-6 sm:px-6">
          <div className="flex items-center justify-between text-base font-medium text-gray-900">
            <p>{t("Subtotal")}</p>
            <p>{selectedCurrency} {calculateTotal().subtotal.toFixed(2)}</p>
          </div>
          <div className="flex items-center justify-between text-base font-medium text-gray-900">
            <p>{t("Shipping")}</p>
            <p>{selectedDeliveryMethod ? selectedDeliveryMethod.price : t("Select delivery method")}</p>
          </div>
          <div className="flex items-center justify-between text-base font-medium text-gray-900">
            <p>{t("Total")}</p>
            <p>{selectedCurrency} {calculateTotal().total.toFixed(2)}</p>
          </div>
        </dl>
        <div className="border-t border-gray-200 px-4 py-6 sm:px-6">
          <button
          onClick={handleAddressSubmit}
            type="submit"
            className="w-full rounded-md border border-transparent bg-indigo-600 px-4 py-3 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-50"
          >
            {t("Confirm order")}  


          </button>
        </div>
      </div>
    </div>
  </div>
</div>
    </div>
  )
} 
   

export default Cart;
