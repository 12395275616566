import React from "react"
import  { useState, useEffect, Fragment } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import LanguageSelector from "./languageSelector";
import CurrencySelector from "./currencySelector";
import { useTranslation } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import Slider from "react-slick";
//import "interactive.js";
import Modal from "react-modal";
import { Dialog, DialogPanel } from "@headlessui/react";
import {XMarkIcon,ShoppingBagIcon,Bars3Icon,} from "@heroicons/react/24/outline";
import axios from "axios";
import {Disclosure,DisclosureButton,DisclosurePanel,} from "@headlessui/react";
import { PlusIcon, MinusIcon } from "@heroicons/react/20/solid";
import { useGlobalState } from "./context/globalContext";
import { changeLanguage } from "i18next";

const MainPage = ({ Main }) => {
  const {
    addToCart,
    decreaseQuantity,
    currency,
    cart,
    totalQuantity,
    orderCodeChangeHanlder,
    orderCode,
  } = useGlobalState();
  const [quantity, setQuantity] = useState(1);
  const [selectedCurrency, setSelectedCurrency] = useState("SAR");
  const [showFooter, setShowFooter] = useState(false);
  const [modalStates, setModalStates] = useState({
    privacy: false,
    terms: false,
    refund: false,
  });
  const [cartItemsCount, setCartItemsCount] = useState(0);
  const [cartItems, setCartItems] = useState([]);
  const [product, setProduct] = useState({});
  const [isRtl, setIsRtl] = useState(false);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  useEffect(() => {
   // changeLanguage(LanguageDetector);
   changeLanguage('ar');
    logPageVisit();

  }, []);

  useEffect(() => {
    let item = cart.find((a) => a.Id == product.Id);
    if (item) {
      product.quantity = item.quantity;
      setQuantity(item.quantity);
      setShowFooter(true);
    } else {
      setQuantity(1);
    }
  }, [cart, currency]);

  const logPageVisit = async () => {
    try {
      localStorage.removeItem("WPData");
      const query = new URLSearchParams(window.location.search);
      const paramValue = query.get("wp"); // Replace 'myParam' with the name of your query parameter
      const WPCode = localStorage.setItem("WPCode",paramValue);
      const VCode = localStorage.getItem("VCode");
      const VC = "0";

   //   useEffect(()=>{
        axios.post('https://wrqmal.com/api/Retrieve/Product',{"wp":paramValue})
          .then(response => {
     
        const data = response.data;
              setProduct({
                name: i18n.language === 'ar' ? data.Ar.Product_Title : data.En.Product_Title,
                price: data.Prices[selectedCurrency],
                href: '#',
     
                images: [
                  { id: 1, src: data.Images.Main, alt: 'Main product image' },
                  ...data.Images.Thumbnail.map(img => ({
                    id: img.ID,
                    src: img.URL,
                    alt: 'Thumbnail image',
                  })),
                ],
                description: i18n.language === 'ar' ? data.Ar.Product_Description : data.En.Product_Description,
                features: data[i18n.language === 'ar' ? 'Ar' : 'En'].Features,
              });
          //    updateCurrency(data['default_currency']);
              
              handleCurrencyChange(data['default_currency']);
              handleLanguageChange(data['default_language']);
              localStorage.setItem("WPData", JSON.stringify(data, null, 2));
            })
            .catch(error => console.error('Error fetching product data:', error));
       // }, [selectedCurrency]);

   //   if (VCode)
    //  {
        
   //   }
    //  if (VCode) return;


      const payload = {
       VCode: localStorage.getItem("VCode")? localStorage.getItem("VCode") : "",
        WorkspaceP_Code: paramValue,
        page: "MainPage",
        url: window.location.href,
        queryParam: window.location.search,
        screenWidth: window.innerWidth,
        screenHeight: window.innerHeight,
      };

      const ret = await axios.post(
        "https://wrqmal.com/api/Visitors_Page/create",
        payload
      );
      console.log(payload);
      console.log("Response:", ret.data);
      if (ret.data && ret.data.VCode) {
        localStorage.setItem("VCode", ret.data.VCode);
      } else {
        alert("System Error, please try again later");
        console.error("VCode not returned from API:", ret.data);
      }
    } catch (error) {
      console.error("Error logging page visit:", error);
    }
  };



  //const ret = {
  //  Id: 1001,
  //  WorkspaceP_Code: "EIJkJY54Bc",
  //  Supported_Countries: ["Saudi Arabia"],
  //  Supported_Currencies: ["SAR", "USD", "EUR"],
  //  Supported_Languages: ["Ar", "En"],
  //  Images: {
  //    Main: "https://m.media-amazon.com/images/I/61qtbSKEtoL._AC_UF1000,1000_QL80_.jpg",
  //    Thumbnail: [
 //       {
 //         URL: "https://media.npr.org/assets/img/2023/03/21/cx_lactator_specimens_custom-4e09ebe3f32eeafcc518a72f00c0ad2c6724c712.jpg",
 //         ID: 1234,
 //       },
 //       {
 //         URL: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQHfoW8CRxbiMoQMi4mnEMNX_Y-_Z_ArCOBUA&s",
//          ID: 12345,
 //       },
 //       {
 //         URL: "https://www.sansiled.com/cdn/shop/articles/banner_2_b9aae4d1-0c5f-41c8-99e0-7d8f1e8db9f8.jpg?v=1636611263&width=2048",
 //         ID: 12346,
//        },
//      ],
//    },
//    Prices: {
//      SAR: 45,
//      USD: 15,
//      EUR: 15,
//    },
//    ar: {
//      Product_Title: "باود صائد النامس",
//      Product_Description: "جهاز لصيد النامس يعمل باضاءة الفوق البنفسجية",/
//      Features: ["بلا رائحة", "هادىء", "انيق"],
//    },
//    en: {
//      Product_Title: "Paud Mosquito Repellent",
//      Product_Description: "UV Light mosquito device",
//      Features: ["no smell", "quiet", "Aesthetic"],
//    },
//  };

 // localStorage.setItem("WPData", JSON.stringify(ret, null, 2));
 // useEffect(() => {
  //  const data = ret;
    //localStorage.setItem("WPData",JSON.stringify(data,null,2));
   // setProduct({
      // name: i18n.language === 'ar' ? data[i18n.language ].Product_Title : data.En.Product_Title,
    //  id: data.Id,
    //  name: data[i18n.language].Product_Title,

  //    price: data.Prices[currency],
  //    href: "#",
  //    images: [
  //      { id: 1, src: data.Images.Main, alt: "Main product image" },
  //      ...data.Images.Thumbnail.map((img) => ({
  //        id: img.ID,
  //        src: img.URL,
  //        alt: "Thumbnail image",
  //      })),
 //     ],
      // description: i18n.language === 'ar' ? data.Ar.Product_Description : data.En.Product_Description,
      //features: data[i18n.language === 'ar' ? 'Ar' : 'En'].Features,
//      description: data[i18n.language].Product_Description,
//      features: data[i18n.language].Features,
//    });
//  }, [currency, i18n.language]);
  // Fetch data from API

  async function createOrder() {
    product.quantity = quantity;
    const VCode = localStorage.getItem("VCode");
    const dat = JSON.parse(localStorage.getItem("WPData"));
    console.log(dat);
    const payload = {
      WorkspaceP_Code: dat.WorkspaceP_Code,
      VCode: VCode,
      quantity: product.quantity,
      unitPrice: product.price,
      total: product.price * quantity,
      currency: selectedCurrency,
      language:i18n.language
    };

    // orderCode;

    try {
      const ret = await axios.post(
        "https://wrqmal.com/api/Orders/create",
        payload
      );
      if (ret.data.length === 0) {
        console.error("Error reserving item:", ret.data);
      } else {
        localStorage.setItem("Order_Code", ret.data.Order_Code);
        console.log("Item reserved successfully:", ret.data);
        if (ret.data.success == false) {
          alert("Error submitting order");
        }

        // if (ret.Supported_Countries.includes("Saudi Arabia")) {
        navigate("/shippingINFO", { state: { cartItems } }); // Navigate to Map if supported
        //  } else {
        //  navigate('/cart', { state: { cartItems } }); // Navigate to cart otherwise
        //}
      }
    } catch (error) {
      console.error("Error reserving item:", error);
    }
  }
  useEffect(()=>{handleCurrencyChange(currency);},[selectedCurrency,currency]);
  useEffect(() => {
    document.documentElement.dir = i18n.language === "ar" ? "rtl" : "ltr";
    setIsRtl(i18n.language === "ar");
  }, [i18n.language]);

  const handleLanguageChange = (lang) => {

    i18n.changeLanguage(lang);
  };

  const handleCurrencyChange = (currencyinp) => {
    setSelectedCurrency(currencyinp);
   // updateCurrency(currencyinp);
    console.log("handle currency called");
    console.log(currency);
    var strData = localStorage.getItem("WPData");
    var data =  JSON.parse(strData);
    console.log(data);
    console.log(selectedCurrency);
    console.log(currencyinp);
    console.log(currency);
    if(data!==null)
    {

      setProduct({
        name: i18n.language === 'ar' ? data.Ar.Product_Title : data.En.Product_Title,
        price: data.Prices[currency],
        href: '#',
  
        images: [
          { id: 1, src: data.Images.Main, alt: 'Main product image' },
          ...data.Images.Thumbnail.map(img => ({
            id: img.ID,
            src: img.URL,
            alt: 'Thumbnail image',
          })),
        ],
        description: i18n.language === 'ar' ? data.Ar.Product_Description : data.En.Product_Description,
        features: data[i18n.language === 'ar' ? 'Ar' : 'En'].Features,
      });
      console.log(data.Prices[currency]);
    }
    

    localStorage.setItem("selectedCurrency",currency);
  };

  // const handleQuantityChange = (e) => {
  //   setQuantity(Math.max( 1, e.target.value));
  // };
  const handleQuantityChange = (e) => {
    const newQuantity = Math.max(1, Number(e.target.value));
    setQuantity(newQuantity);
    setShowFooter(false);
  };

  const incrementQuantity = () => {
    setQuantity((prevQuantity) => Math.min(prevQuantity + 1, 100));
    setShowFooter(false);
    // addToCart(product);
  };

  const decrementQuantity = () => {
    setQuantity((prevQuantity) => Math.max(prevQuantity - 1, 1));
    setShowFooter(false);
    // decreaseQuantity(product);
  };
  const clearStorage = () =>
  {
    localStorage.removeItem("VCode");
    localStorage.removeItem("WPData");
    localStorage.removeItem("WPCode");
    localStorage.removeItem("Order_Code");
    console.log("Cleared");
  }
  // const handleAddToCart = () => {

  //   const newItem = { ...product, quantity, image: product.images[0]?.src };
  //   setCartItems(prevItems => [...prevItems, newItem]);
  //   setCartItemsCount(prevCount => prevCount + quantity);
  //   // totalPrice(prevTotal => prevTotal + newItem.price * quantity);
  //   setShowFooter(true);
  // };
  const handleAddToCart = async () => {
    // const newItem = { ...product, quantity, image: product.images[0]?.src };
    // setCartItems(prevItems => [...prevItems, newItem]);
    // setCartItemsCount(prevCount => prevCount + quantity);
    // totalPrice(prevTotal => prevTotal + newItem.price * quantity);
    product.quantity = quantity;
    addToCart(product);

    setShowFooter(true);
  };

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  const toggleModal = (modal) => {
    setModalStates((prevState) => ({
      ...prevState,
      [modal]: !prevState[modal],
    }));
  };

  Modal.setAppElement("#root");

  const totalPrice = quantity * (product.price || 0);

  return (
    <div className="relative isolate bg-white px-6 py-24 sm:py-32 lg:px-8">
      <div
        aria-hidden="true"
        className="absolute inset-x-0 -top-3 -z-10 transform-gpu overflow-hidden px-36 blur-3xl"
      >
        <div
          style={{
            clipPath:
              "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
          }}
          className="mx-auto aspect-[1155/678] w-[72.1875rem] bg-gradient-to-tr from-[#e680ff] to-[#9089fc] opacity-30"
        />
      </div>
      <div className="mx-auto max-w-2xl text-center lg:max-w-4xl"></div>
      <header className="fixed top-0 left-0 right-0 bg-white z-50 shadow-md">
        <Disclosure as="nav" className="bg-white">
          <div className="relative bg-white">
            <div className="flex h-16 items-center justify-between">
              <div
                className={`absolute inset-y-0 ${isRtl ? "right-0" : "left-0"
                  } flex items-center sm:hidden `}
              >
                {/* Mobile menu button */}
                <Disclosure.Button className="inline-flex items-center justify-center p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="sr-only">Open main menu</span>
                  <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  <XMarkIcon className="hidden h-6 w-6" aria-hidden="true" />
                </Disclosure.Button>
              </div>
              <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start text-left">
                <div
                  className={`ml-4 flex flex-shrink-0 items-center ${isRtl ? "mr-2" : ""
                    }`}
                >
                  <a
                    href="https://excellent-brands.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img height="50px" width="50px;" src="https://froncdn3.blob.core.windows.net/cdn1/shorthandLogo.svg"/>
                  </a>
                  <span
                    className={`ml-2 text-xl text-gray ${isRtl ? "mr-2" : ""}`}
                  >
                    Excellent Brands
                  </span>
                </div>
                <div className="hidden sm:ml-6 sm:flex sm:space-x-4">
                  <LanguageSelector
                    // onLanguageChange={handleLanguageChange}
                    className="ml-4 inline-flex w-32 items-center justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  />
                  <CurrencySelector
                  //   selectedCurrency={selectedCurrency}
                  //   onCurrencyChange={handleCurrencyChange}
                    className="ml-4 inline-flex w-32 items-center justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  />
                </div>
              </div>
              {/* Cart button positioned to the right */}
              <div
                className={`absolute ${isRtl ? "left-0" : "right-0"
                  } top-1/2 transform -translate-y-1/2 mr-4 ml-4`}
              >
                <button
                  onClick={() => {
                   // if (ret.Supported_Countries.includes("Saudi Arabia")) {
                      navigate("/shippingINFO", { state: { cartItems } }); // Navigate to Map if supported
                  //  } else {
                  //    navigate("/cart", { state: { cartItems } }); // Navigate to cart otherwise
                  //  }
                  }}
                  className="inline-flex items-center justify-center"
                >
                  <ShoppingBagIcon className="h-5 w-5 mr-2" />
                  {totalQuantity}
                </button>
              </div>
            </div>
          </div>
          <Disclosure.Panel className="sm:hidden">
            <div className="px-20 pb-4 flex flex-row">
              <LanguageSelector
                // onLanguageChange={handleLanguageChange}
                className="ml-4 inline-flex w-32 items-center justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              />
              <CurrencySelector
          //       selectedCurrency={selectedCurrency}
          //       onCurrencyChange={handleCurrencyChange}
                className="ml-4 inline-flex w-32 items-center justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              />
            </div>
          </Disclosure.Panel>
        </Disclosure>
      </header>

      <main className="pt-16 pb-16 flex-1 overflow-auto">
        <div className="container mx-auto py-6">
          <div
            className={` mx-auto max-w-2xl px-4 pb-10 pt-5 sm:px-6 lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-12 lg:gap-x-8  ${isRtl ? "ml-auto" : ""
              }`}
          >
            <div
              className={`lg:col-span-7 ${isRtl ? "ml-2" : ""}`}
              style={{ width: "60%", margin: "auto" }}
            >
              <Slider {...sliderSettings}>
                {product.images &&
                  product.images.map((image) => (
                    <div key={image.id} className="w-full">
                      <img
                        src={image.src}
                        alt={image.alt}
                        className="w-full h-auto object-cover"
                      />
                    </div>
                  ))}
              </Slider>

              <div className="grid grid-cols-4 lg:gap-4 mt-6">
                {product.images &&
                  product.images.map((image) => (
                    <img
                      key={image.id}
                      src={image.src}
                      alt={image.alt}
                      className="rounded-lg w-full h-auto object-cover cursor-pointer"
                      onClick={() => window.open(image.src, "_blank")}
                    />
                  ))}
              </div>
            </div>

            <div className={`lg:col-span-5 ml-10 ${isRtl ? "mr-10" : ""}`}>
              <h1
                className={`mt-5 text-xl sm:text-2xl lg:text-3xl font-extrabold  text-gray-900 ${isRtl ? "ml-2 text-right" : ""
                  }`}
              >
                {product.name}
                <p
                  className={`inline-block text-base sm:text-lg lg:text-xl pt-10 font-bold text-gray-900  ${isRtl ? "mr-4" : ""
                    }`}
                >
                  {currency} {product.price && product.price.toFixed(2)}
                </p>
              </h1>
              {/* <p className={`mt-2 text-sm sm:text-base lg:text-lg font-bold text-gray-700 ${isRtl ? 'ml-2 text-right' : ''}`} dangerouslySetInnerHTML={{ __html: product.description }} />
            <div className="mt-20 flex text-left ">
              <label htmlFor="quantity" className={`  block text-lg font-bold text-gray-900 mt-2 ${isRtl ? 'ml-2' : ''}`}>  {t('Quantity')}  </label>
              <input
                type="number"
                id="quantity"
                min="1"
                max="100"
                value={quantity}
                onChange={handleQuantityChange}
                className="mt-2 block w-24 px-3 py-1 border border-gray-300 rounded-md shadow-sm ml-2"
              />
            </div> */}
              <p
                className={`mt-2 text-sm sm:text-base lg:text-lg font-bold text-gray-700 ${isRtl ? "ml-2 text-right" : ""
                  }`}
                dangerouslySetInnerHTML={{ __html: product.description }}
              />
              <div className="mt-20 flex items-center text-left">
                <label
                  htmlFor="quantity"
                  className={`block text-lg font-bold text-gray-900 mt-2 ${isRtl ? "ml-2" : ""
                    }`}
                >
                  {t("Quantity")}
                </label>
                <div className="flex items-center ml-2">
                  <button
                    type="button"
                    onClick={decrementQuantity}
                    className={`px-3 py-1 border border-gray-300 bg-white rounded-l-md ${isRtl ? "rounded-r-md" : ""
                      }`}
                  >
                    -
                  </button>
                  <input
                    // type="number"
                    id="quantity"
                    min="1"
                    max="100"
                    value={quantity}
                    onChange={handleQuantityChange}
                    className="w-24 px-3 py-1 border border-gray-300 rounded-md text-center"
                  />
                  <button
                    type="button"
                    onClick={incrementQuantity}
                    className={`px-3 py-1 border border-gray-300 bg-white rounded-r-md ${isRtl ? "rounded-l-md" : ""
                      }`}
                  >
                    +
                  </button>
                </div>
              </div>

              <div className={`mt-10 flex ${isRtl ? "ml-2" : ""}`}>
                <button
                  type="submit"
                  onClick={handleAddToCart}
                  className="flex max-w-xs flex-1 items-center justify-center rounded-md border border-transparent bg-indigo-600 px-8 py-3 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-50 sm:w-full"
                >
                  {t("Add to cart")}
                </button>
              </div>

              <section
                aria-labelledby="details-heading"
                className={`mt-12 ${isRtl ? "ml-2" : ""}`}
              >
                <div className="divide-y divide-gray-200 border-t">
                  {product.features && (
                    <Disclosure>
                      {({ open }) => (
                        <>
                          <DisclosureButton className="group relative flex w-full items-center justify-between py-6 text-left">
                            <span className="text-sm font-bold text-gray-900 group-data-[open]:text-indigo-600">
                              {t("Features")}
                            </span>
                            <span className="ml-6 flex items-center">
                              <PlusIcon
                                aria-hidden="true"
                                className="block h-6 w-6 text-gray-400 group-hover:text-gray-500 group-data-[open]:hidden"
                              />
                              <MinusIcon
                                aria-hidden="true"
                                className="hidden h-6 w-6 text-indigo-400 group-hover:text-indigo-500 group-data-[open]:block"
                              />
                            </span>
                          </DisclosureButton>
                          <DisclosurePanel
                            className={`prose prose-sm pb-6 text-left ${isRtl ? "ml-2 text-right" : ""
                              }`}
                          >
                            <ul
                              role="list"
                              className={`list-disc  pl-5 ${isRtl ? "pr-5" : ""
                                } custom-list`}
                            >
                              {product.features.map((feature, index) => (
                                <li key={index} className="custom-list-item">
                                  {feature}
                                </li>
                              ))}
                            </ul>
                          </DisclosurePanel>
                        </>
                      )}
                    </Disclosure>
                  )}

                  <Disclosure>
                    {({ open }) => (
                      <>
                        <DisclosureButton className="group relative flex w-full items-center justify-between py-6 text-left">
                          <span className="text-sm font-bold text-gray-900 group-data-[open]:text-indigo-600">
                            {t("Our Policies")}
                          </span>
                          <span className="ml-6 flex items-center">
                            <PlusIcon
                              aria-hidden="true"
                              className="block h-6 w-6 text-gray-400 group-hover:text-gray-500 group-data-[open]:hidden"
                            />
                            <MinusIcon
                              aria-hidden="true"
                              className="hidden h-6 w-6 text-indigo-400 group-hover:text-indigo-500 group-data-[open]:block"
                            />
                          </span>
                        </DisclosureButton>
                        <DisclosurePanel className="prose prose-sm pb-6 ">
                          <div className="flex flex-col ">
                            <button
                              onClick={() => toggleModal("privacy")}
                              className={`text-black mb-2 text-left ${isRtl ? "ml-2 text-right" : ""
                                }`}
                            >
                              {" "}
                              {t("Privacy Policy")}
                            </button>
                            <button
                              onClick={() => toggleModal("terms")}
                              className={`text-black mb-2 text-left ${isRtl ? "ml-2 text-right" : ""
                                }`}
                            >
                              {" "}
                              {t("Terms & Conditions")}
                            </button>
                            <button
                              onClick={() => toggleModal("refund")}
                              className={`text-black text-left ${isRtl ? "ml-2 text-right" : ""
                                }`}
                            >
                              {" "}
                              {t("Refund Policy")}
                            </button>
                          </div>
                        </DisclosurePanel>
                      </>
                    )}
                  </Disclosure>
                </div>
              </section>
            </div>
          </div>
        </div>
      </main>

      {showFooter && (
        <footer className="fixed bottom-0 left-0 right-0 bg-gray-200 py-4">
          <div className="container mx-auto flex justify-between items-center px-4 font-bold">
            <span>
              {t("Total Price:")} {currency} {totalPrice.toFixed(2)}
            </span>
            <button
              onClick={() => {
                createOrder();
              }}
              className="text-white bg-indigo-600 px-4 py-2 rounded-md hover:bg-indigo-700 text-left "
            >
              {t("Checkout")} ({totalQuantity} {t("items")})
            </button>
          </div>
        </footer>
      )}

      {/* Privacy Policy Modal */}
      <Dialog
        open={modalStates.privacy}
        onClose={() => toggleModal("privacy")}
        className={`fixed inset-0 flex items-center justify-center z-50 overflow-auto p-4 ${isRtl ? "ml-2" : ""
          }`}
      >
        <DialogPanel className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full">
          <div className="flex justify-between items-center">
            <h3 className="text-lg font-semibold mb-4">
              {t("Privacy Policy")}
            </h3>
          </div>
          <p className="text-sm text-gray-700 leading-relaxed max-h-[60vh] overflow-y-auto">
            {t("At Company A, we value your privacy.")}
            <br />
            {t(
              "This Privacy Policy explains how we collect, use, and protect your personal information."
            )}
            <br />
            <br />
            <strong>{t("1. Information We Collect")}</strong>

            <br />
            {t(
              "1.1. Personal Information| Name, email address, phone number ,IP Address, Geographical location upon user approval."
            )}
            <br />
            {t("1.2. Transaction Information| Details about your purchases.")}
            <br />
            {t("1.3. Technical Information| IP address, browser type, etc.")}

            <br />
            <br />
            <strong>{t("2. How We Use Your Information")}</strong>
            <br />
            {t("2.1. To process and manage your orders.")}
            <br />
            {t(
              "2.2. To communicate with you about your orders and promotions."
            )}
            <br />
            {t("2.3. To improve our website and services.")}
            <br />
            <br />
            <strong>{t("3. How We Protect Your Information")}</strong>
            <br />
            {t("3.1. We implement security measures to protect your data.")}
            <br />
            {t(
              "3.2. We do not sell or rent your personal information to third parties."
            )}
            <br />
            <br />
            <strong>{t("4. Cookies")}</strong>
            <br />
            {t(
              "4.1. We use cookies to enhance your experience on our website."
            )}
            <br />
            {t(
              "4.2. You can choose to disable cookies in your browser settings."
            )}
            <br />
            <br />
            <strong>{t("5. Third-Party Links")}</strong>
            <br />
            {t(
              "5.1. Our website may contain links to other sites. We are not responsible for their privacy practices."
            )}
            <br />
            <br />
            <strong>{t("6. Your Rights")}</strong>
            <br />
            {t(
              "6.1. You have the right to access and update your personal information."
            )}
            <br />
            {t(
              "6.2. You may request to delete your personal information by contacting our support team at Support@ExcellentB.sa"
            )}
            <br />
            <br />
            <strong>{t("7. Changes to This Policy")}</strong>
            <br />
            {t(
              "7.1. We may update this Privacy Policy from time to time. Any changes will be posted on this page."
            )}
            <br />
            <br />
            <strong>{t("Contact Us")}</strong>
            <br />
            {t(
              "For any questions about this Privacy Policy, please contact us at Support@ExcellentB.sa"
            )}
            <br />
            <br />
            {t("Version: 1.01 Release Date: 2024-08-24")}
          </p>
          <button
            onClick={() => toggleModal("privacy")}
            className="mt-4 rounded-md bg-indigo-600 px-4 py-3 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            close
          </button>
        </DialogPanel>
      </Dialog>

      {/* Terms of Service Modal */}
      <Dialog
        open={modalStates.terms}
        onClose={() => toggleModal("terms")}
        className={`fixed inset-0 flex items-center justify-center z-50 overflow-auto p-4 ${isRtl ? "ml-2" : ""
          }`}
      >
        <DialogPanel className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full">
          <div className="flex justify-between items-center">
            <h3 className="text-lg font-semibold mb-4">
              {t("Terms & Conditions")}
            </h3>
          </div>
          <p className="text-sm text-gray-700 leading-relaxed max-h-[60vh] overflow-y-auto">
            {t("Welcome to A Website!")}
            <br />
            {t(
              "By using our website and services, you agree to the following terms and conditions. Please read them carefully."
            )}
            <br />
            <br />
            <strong>{t("1. General")}</strong>
            <br />
            {t(
              "1.1. These Terms & Conditions govern your use of our website and services."
            )}
            <br />
            {t(
              "1.2. We reserve the right to update these terms at any time. Your continued use of the site constitutes acceptance of any changes."
            )}
            <br />
            <br />
            <strong>{t("2. User Responsibilities")}</strong>
            <br />
            {t("2.1. You must be at least 18 years old to use our services.")}
            <br />
            {t(
              "2.2. You are responsible for maintaining the confidentiality of your account and password and for all activities that occur under your account."
            )}
            <br />
            <br />
            <strong>{t("3. Products and Services")}</strong>
            <br />
            {t(
              "3.1. We strive to provide accurate product descriptions and images. However, we do not warrant that product descriptions are accurate, complete, reliable, or error-free."
            )}
            <br />
            {t(
              "3.2. Prices and availability of products are subject to change without notice."
            )}
            <br />
            <br />
            <strong>{t("4. Payments")}</strong>
            <br />
            {t(
              "4.1. Payments must be made through the payment methods we accept."
            )}
            <br />
            {t("4.2. All transactions are processed securely.")}
            <br />
            <br />
            <strong>{t("5. Shipping and Delivery")}</strong>
            <br />
            {t(
              "5.1. We will make every effort to deliver your order within the estimated delivery time."
            )}
            <br />
            {t(
              "5.2. We are not responsible for delays caused by external factors."
            )}
            <br />
            <br />
            <strong>{t("6. Returns and Refunds")}</strong>
            <br />
            {t(
              "6.1. Please refer to our Refund Policy for information on returns and refunds."
            )}
            <br />
            <br />
            <strong>{t("7. Limitation of Liability")}</strong>
            <br />
            {t(
              "7.1. We are not liable for any indirect, incidental, or consequential damages arising from the use of our website or services."
            )}
            <br />
            <br />
            <strong>{t("8. Governing Law")}</strong>
            <br />
            {t("8.1. These terms are governed by the laws of Saudi Arabia.")}
            <br />
            <br />
            <strong>{t("Contact Us")}</strong>
            <br />
            {t(
              "If you have any questions about these Terms & Conditions, please contact us at Support@ExcellentB.sa"
            )}
            <br />
            {t("Version: 1.01")}
          </p>
          <button
            onClick={() => toggleModal("terms")}
            className=" mt-4 rounded-md bg-indigo-600 px-4 py-3 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            close
          </button>
        </DialogPanel>
      </Dialog>

      {/* Refund Policy Modal */}
      <Dialog
        open={modalStates.refund}
        onClose={() => toggleModal("refund")}
        className={`fixed inset-0 flex items-center justify-center z-50 overflow-auto p-4 ${isRtl ? "ml-2" : ""
          }`}
      >
        <DialogPanel className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full">
          <div className="flex justify-between items-center">
            <h3 className="text-lg font-semibold mb-4">{t("Refund Policy")}</h3>
            {/* <button onClick={() => toggleModal('refund')} className="text-gray-600 hover:text-gray-900">
        <XMarkIcon className="h-6 w-6" />
      </button> */}
          </div>
          <p className="text-sm text-gray-700 leading-relaxed max-h-[60vh] overflow-y-auto">
            {t("At A company, we want you to be satisfied with your purchase.")}
            <br />
            <br />
            <strong>{t("1. Eligibility for Refunds")}</strong>
            <br />
            {t(
              "1.1. You may request a refund if the product is defective, not as described, or if you receive the wrong item."
            )}
            <br />
            {t(
              "1.2. Refunds are only available within [30] days of the purchase date."
            )}
            <br />
            <br />
            <strong>{t("2. How to Request a Refund")}</strong>
            <br />
            {t(
              "2.1. Contact our customer service team at Support@ExcellentB.sa to initiate the refund process."
            )}
            <br />
            {t(
              "2.2. Provide your order number and reason for the refund request."
            )}
            <br />
            <br />
            <strong>{t("3. Refund Process")}</strong>
            <br />
            {t(
              "3.1. Once your refund request is approved, we will process it within [7-10] business days."
            )}
            <br />
            {t("3.2. Refunds will be issued to the original payment method.")}
            <br />
            <br />
            <strong>{t("4. Non-Refundable Items")}</strong>
            <br />
            {t(
              "4.1. Certain items, such as [customized products, perishable goods], are not eligible for refunds."
            )}
            <br />
            <br />
            <strong>{t("5. Shipping Costs")}</strong>
            <br />
            {t("5.1. Shipping costs are non-refundable.")}
            <br />
            <br />
            <strong onClick={()=> clearStorage()}>{t("6. Exchanges")}</strong>
            <br />
            {t(
              "6.1. If you wish to exchange a product, please follow our return process and place a new order."
            )}
            <br />
            <br />
            <strong>{t("Contact Us")}</strong>
            <br />
            {t(
              "For any questions about our Refund Policy, please contact us at support@ExcellentB.sa"
            )}
          </p>
          <button
            onClick={() => toggleModal("refund")}
            className=" mt-4 rounded-md bg-indigo-600 px-4 py-3 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            close
          </button>
        </DialogPanel>
      </Dialog>
    </div>
  );
};

export default MainPage;
