import React, { useEffect, useState } from "react";
import {GoogleMap,LoadScript,Marker,useJsApiLoader,} from "@react-google-maps/api";

import { useTranslation } from "react-i18next";
import axios from "axios";
import { Disclosure } from "@headlessui/react";
import LanguageSelector from "./languageSelector";
import {HashLoader} from "react-spinners";
import CurrencySelector from "./currencySelector";
import {Bars3Icon,XMarkIcon,ShoppingBagIcon,} from "@heroicons/react/20/solid";
import { useLocation, useNavigate } from "react-router-dom";
import { RadioGroup, Radio } from "@headlessui/react";
import "react-phone-number-input/style.css";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { isPossiblePhoneNumber } from 'react-phone-number-input'
import SearchableDropdown from "./SearchableDropdown";
import {CitySelect,CountrySelect,StateSelect,LanguageSelect,
  
} from "react-country-state-city";
import {GetCountries,GetState,GetCity,GetLanguages,GetRegions,GetPhonecodes } from "react-country-state-city";
  //async functions} 
import "react-country-state-city/dist/react-country-state-city.css";
import { useGlobalState } from "./context/globalContext";
import { saudiArabiaDistricts } from "./common";
import { countriesOptions, citiesOptions ,districtsOptions} from "./country-city";
import Select from 'react-select'

import SearchableInput from "./searchInput";
const API_KEY = "AIzaSyBiFSvbtlW9ovAK0RG1VQvQVLkq2z_y1WY"; // Ensure to replace with your API key
const containerStyle = {
  width: "100%",
  height: "400px",
};
const center = { lat: 24.7117, lng: 46.6715 };



const Map = (props) => {
  // Default to an empty array if cartItems is undefined
  const {
    addToCart,
    decreaseQuantity,
    increaseQuantity,
    removeFromCart,
    currency,
    cart,
    totalQuantity,
  } = useGlobalState();
  const location = useLocation();
  const cartItems = location.state?.cartItems || [];
  const isEmail = (email) =>
    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const { t, i18n } = useTranslation();
  const [selectedLocation, setSelectedLocation] = useState(center);
  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [shortCode, setShortCode] = useState("");
  const [city, setCity] = useState("");
  const [deliveryMethods,setDeliveryMethods] = useState([]);
  const [district, setDistrict] = useState("");
  const [postCode, setPostCode] = useState("");
  const [showError, setShowError] = useState("");
  const [countryid, setCountryid] = useState(0);
  const [stateid, setstateid] = useState(0);
  const [vatPerc,setVatPerc] = useState(0);
  const [alreadySubmitted,setAlreadySubmitted] = useState(0);
  const [countriesList, setCountriesList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [autoLocation,setAutoLocation] = useState(true);
  const [countryPlaceHolder,setCountryPlaceHolder] = useState(t('selectCountry'));
  const [statePlaceHolder,setStatePlaceHolder] = useState(t('selectState'))
  const [cityPlaceHolder, setCityPlaceHolder] = useState(false);
  const [showSaudiRelated,setShowSaudiRelated] = useState(false);
  const [cityOptions,setCityOptions] = useState([]);
  const [districtOptions,setDistrictOptions]= useState([]);
  const [wpdata, setWPData] = useState({ Supported_Countries: ["country"] });
  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    addressLine1: "",
    addressLine2: "",
    country: "",
    state: "",
    city: "",
    district: "",
    postCode: "",
    cart: "",
    deliveryMethod:""
  });
  const [selectedCurrency, setSelectedCurrency] = useState(localStorage.getItem("selectedCurrency") ?localStorage.getItem("selectedCurrency") :"USD" );
  const [product, setProduct] = useState([]);
  const [isRtl, setIsRtl] = useState(false);
  const [cartItemsCount, setCartItemsCount] = useState(cartItems.length);
  const [shippingOptions, setShippingOptions] = useState([]);
  const [selectedDistrict, setSelectedDistrict] = useState("");

  var defCountry = "Saudi Arabia";
  
  var Districts;
  useEffect(() => {
    const a = localStorage.getItem("WPData");
    setWPData(JSON.parse(a));
    // Update cart items count based on the cartItems array
    setCartItemsCount(
      cartItems.reduce((total, item) => total + item.quantity, 0)
    );
  }, [cartItems]);

  const [message, setMessage] = useState("");
  const [selectedDeliveryMethod, setSelectedDeliveryMethod] = useState(
    deliveryMethods[0]
  );
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: API_KEY,
    libraries: ["places"],
  });
  const navigate = useNavigate();
  useEffect(() => {

    GetCountries().then((result) => {
      setCountriesList(result);
    });
    GetState().then((result) => {
      setStateList(result);
    });

    GetCity().then((result) => {
      setCityList(result);
    });
  }, []);
  useEffect(() => {
    const wpdata1 = localStorage.getItem("WPData");
    setWPData(wpdata1);
  //  axios
    //  .get("https://spotsaletest.azurewebsites.net/sampleVar1")
   //   .then((response) => {
        const data = JSON.parse(wpdata1);
        const language = i18n.language === "ar" ? "Ar" : "En";
  console.log(data);
        // Fetch product details based on the selected currency
        const fetchedProducts = cart.map((item) => ({
          id: item.id,
          title: data[language].Product_Title,
          price: data.Prices[currency], // Calculate total price for this item
          href: "#",
          imageSrc: data.Images.Main,
          imageAlt: "Main product image",
          quantity: item.quantity,
        }));

        setProduct(fetchedProducts);
   //   })
   //   .catch((error) => console.error("Error fetching product data:", error));
  }, [currency, cart, cartItems, i18n.language]);

  useEffect(() => {
    document.documentElement.dir = i18n.language === "ar" ? "rtl" : "ltr";
    setIsRtl(i18n.language === "ar");
  }, [i18n.language]);

  const handleLanguageChange = (lang) => {
    i18n.changeLanguage(lang);
    if(!country)
    {
      setCountryPlaceHolder(t("selectCountry"));
    }
    if(!city)
    {
      setCityPlaceHolder(t("selectCity"));
    }
  };
  const handleDistrictChange = (dist) => {};

  const handleCurrencyChange = (currency) => {
    setSelectedCurrency(currency);
    calculateTotal();
  };
  const handleAdressGeocode = async (latLng) => {
    try {
      // alert(latLng.lat());
      console.log(latLng);
      // const { lat, lng } = latLng;
      const geocodeResponse = await axios.get(
        "https://maps.googleapis.com/maps/api/geocode/json",
        {
          params: {
            latlng: `${latLng.lat()},${latLng.lng()}`,
            key: API_KEY,
          },
        }
      );
      setAutoLocation(false);
      if (geocodeResponse.data.results.length > 0) {
       
        const addressComponents =
          geocodeResponse.data.results[0].address_components;
        addressComponents.forEach((component) => {
          if (component.types.includes("country")) {
            setCountry(component.long_name);
            // loop through countries to find id , set country id 
            console.log(countriesList);
          }
          if (component.types.includes("locality")) {
            setCity(component.long_name);
          }
          if (component.types.includes("premise")) {
            setShortCode(component.long_name);
          }
          if (component.types.includes("administrative_area_level_2")) {
            setSelectedDistrict(component.long_name);
          }
          if (component.types.includes("postal_code")) {
            setPostCode(component.long_name);
          }
          
        });
        setAddressLine2(geocodeResponse.data.results[0].formatted_address);
      } else {
        setShowError("No address found for the current location.");
      }
    } catch (error) {
      console.error("Error retrieving address:", error);
      setShowError("Could not retrieve address. Please try again.");
    }
  };
  const handleRequestLocation = async () => {
    navigator.geolocation.getCurrentPosition(async (position) => {
      const { latitude, longitude } = position.coords;
      setSelectedLocation({ lat: latitude, lng: longitude });

      try {
        const geocodeResponse = await axios.get(
          "https://maps.googleapis.com/maps/api/geocode/json",
          {
            params: {
              latlng: `${latitude},${longitude}`,
              key: API_KEY,
            },
          }
        );

        if (geocodeResponse.data.results.length > 0) {
          const addressComponents =
            geocodeResponse.data.results[0].address_components;
          addressComponents.forEach((component) => {
            if (component.types.includes("country")) {
              setCountry(component.long_name);
              getShippingPriceDetails(country,"Other");
              for(var i=0;i<countriesList.length;i++)
              {
                if(countriesList[i].name==component.long_name)
                {
                  //found the country 
                  console.log(countriesList[i]);
                  setCountryid(countriesList[i].id);
                  if (countriesList[i].name === "Saudi Arabia") {
                    setShowSaudiRelated(true);
                  } else {
                    setShowSaudiRelated(false);
                  }
                  setCountryPlaceHolder(countriesList[i].name);
                  break;
                }
              }
             
            }
            if (component.types.includes("locality")) {
              setCity(component.long_name);
              getShippingPriceDetails(country,city);
              setCityPlaceHolder(component.long_name);
            }
            if (component.types.includes("administrative_area_level_2")) {
              setSelectedDistrict(component.long_name);
            }
            if (component.types.includes("premise")) {
              setShortCode(component.long_name);
            }
            if (component.types.includes("postal_code")) {
              setPostCode(component.long_name);
            }
          });
          setAddressLine1(geocodeResponse.data.results[0].formatted_address);
        } else {
          setShowError("No address found for the current location.");
        }
      } catch (error) {
        console.error("Error retrieving address:", error);
        setShowError("Could not retrieve address. Please try again.");
      }
    });
  };

  const handleAddressSubmit = async () => {
    const payload = {
      Ocode: localStorage.getItem("Order_Code"),
      VCode: localStorage.getItem("VCode"),
      first_name: firstName,
      last_name: lastName,
      email: email,
      phone: phone,
      address1: addressLine1,
      address2: addressLine2,
      country: country,
      city: city,
      district: selectedDistrict,
      post_code: postCode,
      lat:selectedLocation.lat,
      lng:selectedLocation.lng,
      ShortCode: shortCode,
      deliveryMethod: selectedDeliveryMethod ? selectedDeliveryMethod.id : 0,
      language:i18n.language
    };

    // Validate inputs
    let formIsValid = true;
    if (!firstName) {
      setErrors((prev) => ({ ...prev, firstName: "First name is required." }));
      formIsValid = false;
    } else {
      setErrors((prev) => ({ ...prev, firstName: "" }));
    }
    if (!lastName) {
      setErrors((prev) => ({ ...prev, lastName: "Last name is required." }));
      formIsValid = false;
    } else {
      setErrors((prev) => ({ ...prev, lastName: "" }));
    }
    if (selectedDeliveryMethod==0 || !selectedDeliveryMethod) {
      setErrors((prev) => ({ ...prev, deliveryMethod: "Please select a delivery method." }));
      formIsValid = false;
    } else {
      setErrors((prev) => ({ ...prev, deliveryMethod: "" }));
    }
    if (!email) {
      setErrors((prev) => ({ ...prev, email: "Email is required." }));
      formIsValid = false;
    }
    if (!isEmail(email)) {
      setErrors((prev) => ({ ...prev, email: "Please input a correct email" }));
      formIsValid = false;
    } else {
      setErrors((prev) => ({ ...prev, email: "" }));
    }
    if (!country) {
      setErrors((prev) => ({ ...prev, country: "please select a country" }));
      formIsValid = false;
    } else {
      setErrors((prev) => ({ ...prev, country: "" }));
    }
   // if (!state) {
   //   setErrors((prev) => ({ ...prev, state: "please select a state" }));
  //    formIsValid = false;
  //  } else {
   //   setErrors((prev) => ({ ...prev, state: "" }));
   // }
    if (!city) {
      setErrors((prev) => ({ ...prev, city: "please select the city" }));
      formIsValid = false;
    } else {
      setErrors((prev) => ({ ...prev, city: "" }));
    }
    if (!selectedDistrict && country === "Saudi Arabia") {
      setErrors((prev) => ({
        ...prev,
        district: "please select neighborhood",
      }));
      formIsValid = false;
    } else {
      setErrors((prev) => ({ ...prev, district: "" }));
    }
    if (!postCode) {
      setErrors((prev) => ({ ...prev, postCode: "please enter postcode" }));
      formIsValid = false;
    } else {
      setErrors((prev) => ({ ...prev, postCode: "" }));
    }
    if ((!phone) || (!isValidPhoneNumber(phone))) {
      setErrors((prev) => ({ ...prev, phone: "Phone number entered is invalid." }));
      formIsValid = false;
    } else {
      setErrors((prev) => ({ ...prev, phone: "" }));
    }

    if (!cart || !cart.length > 0) {
      setErrors((prev) => ({ ...prev, cart: "Cart should not be empty." }));
      formIsValid = false;
    } else {
      setErrors((prev) => ({ ...prev, cart: "" }));
    }

    if (!addressLine1) {
      console.log(addressLine1);
      setErrors((prev) => ({
        ...prev,
        addressLine1: "Address Line 1 cannot be empty.",
      }));
      formIsValid = false;
    } else {
      setErrors((prev) => ({ ...prev, addressLine1: "" }));
    }

    if (!formIsValid) return;

    try {
   //   if (alreadySubmitted==1)
   //   {
   //     return;
   //   }
      setAlreadySubmitted(1);
      // Send the address to your server
      const ret = await axios.post(
        "https://wrqmal.com/api/Orders/Address_Submit",
        payload
      );

      console.log(ret.data);
      if (ret.data.length === 0) {
        setShowError("Order not sent or invalid data");
      } else {
       // setMessage("Location saved successfully.");
        if (ret.data.status == false) {
          alert("An error happened placing the order");
        } else {
          const redirectURL = ret.data.toURL; // Assuming `toURL` is included in the response
         window.location.href = redirectURL; // Redirecting the user
        }
      
      }
    } catch (error) {
      console.error("Error submitting address:", error);
      setShowError("Could not submit address. Please try again.");
    }
  };

  const getShippingPriceDetails = async (country, city) => {
    try {
     /// var wp = localStorage.getItem("Order_Code");
     var wp = localStorage.getItem("WPCode");

     axios.post('https://wrqmal.com/api/Retrieve/VAT',{
      'country':country,
      'city':city})
        .then((response) => {
          const data = response.data;
          setVatPerc(data.VATPerc);
          calculateTotal();
        }).catch((error)=>{
          console.error("Error retrieving VAT information:", error);
      setShowError("Could not retrieve VAT Information. Please try again.");
        });
     axios.get(`https://wrqmal.com/api/Retrieve/ShippingOptions?WP=${wp}&country=${country}&city=${city}`)
        .then((response) => {
          const data = response.data;
          
          
          
          for(var i=0; i<data.length;i++)
          {
            data[i]['titles']=[];
            data[i]['titles']['ar'] = data[i]['title_ar'];
            data[i]['titles']['en'] = data[i]['title_en'];
            data[i]['descriptions'] = [];
            data[i]['descriptions']['ar'] = data[i]['description_ar'];
            data[i]['descriptions']['en'] = data[i]['description_en'];

          }
          console.log(data);
          setDeliveryMethods(data);
          setShippingOptions(data);
          // Fetch product details based on the selected currency
        })
        .catch((error) => console.error("Error fetching product data:", error));
    } catch (error) {
      console.error("Error retrieving address:", error);
      setShowError("Could not retrieve shipping address. Please try again.");
    }
  };
function currencyConverter(fcur,tcur,amount)
{
  if (fcur=="USD")
  {
    if(tcur=="USD")
    {
      return amount;
    }
    if(tcur=="SAR")
    {
      return Math.round(amount*3.75*100)/100;
    }
    
  }
  else if (fcur=="SAR")
  {
    if(tcur=="USD")
    {
      return Math.round(amount*100/3.75)/100;
    }
    if(tcur=="SAR")
    {
      return amount;
    }
  }
}
  useEffect(() => {
    document.documentElement.dir = i18n.language === "ar" ? "rtl" : "ltr";
    setIsRtl(i18n.language === "ar");
  }, [i18n.language]);

  const calculateTotal = () => {
    const subtotal = product.reduce(
      (sum, item) => sum + item.price * item.quantity,
      0
    );
    const VAT = vatPerc * subtotal;

    const shipping = selectedDeliveryMethod
      ? parseFloat(currencyConverter("USD",currency,selectedDeliveryMethod.default_fees))
      : 0;
    /////  vat calculation should be here 
    
    const total = subtotal + shipping+VAT;
    return { subtotal,VAT, total };
  };
  useEffect(() => {
    if (isLoaded) {
      const map = new window.google.maps.Map(document.getElementById("map"), {
        center: selectedLocation,
        zoom: 14,
      });

      const marker = new window.google.maps.Marker({
        position: selectedLocation,
        map: map,
      });

      // Update marker position and map center on location change
      const moveMarker = (latLng) => {
        marker.setPosition(latLng);
        map.setCenter(latLng);
        console.log("Move Marker");
        console.log(latLng);
        handleAdressGeocode(latLng);
      };

      map.addListener("click", (event) => {
        const newLatLng = {
          lat: event.latLng.lat(),
          lng: event.latLng.lng(),
        };
        setSelectedLocation(newLatLng);
        moveMarker(event.latLng);
      });

      // Cleanup function to remove the event listener
      return () => {
        new window.google.maps.event.clearListeners(map, "click");
      };
    }
  }, [isLoaded, selectedLocation]);

  useEffect(() => {
    if (city && country) getShippingPriceDetails(country, city);
  }, [city,country]);

  const handleQuantityChange = (e, product) => {
    const newQuantity = Math.max(1, Number(e.target.value));
    product.quantity = newQuantity;
    addToCart(product);
  };

  const incrementQuantity = (product) => {
    increaseQuantity(product);
  };

  const decrementQuantity = (product) => {
    if (product.quantity == 1) {
      return;
    }
    decreaseQuantity(product);
  };

  const deleteItem = (product) => {
    // if (product.quantity == 1) {
    //   return;
    // }
    removeFromCart(product.id);
  };

  const getDistricts = () => {
    const districtsList = Object.entries(saudiArabiaDistricts).find((el) => {
      const [key, value] = el;
      if (String(key).toLowerCase() === String(city).toLowerCase()) return true;
    });

    return districtsList?.length ? districtsList[1] : [];
  };

  const logPageVisit = async () => {
    try {
      const query = new URLSearchParams(window.location.search);
      const paramValue = query.get("wp"); // Replace 'myParam' with the name of your query parameter
      const WPCode = localStorage.setItem("WPCode",paramValue);
      const VCode = localStorage.getItem("VCode");
      const VC = "0";
  
   //   useEffect(()=>{
        axios.post('https://wrqmal.com/api/Retrieve/Product',{"wp":paramValue})
          .then(response => {
     
        const data = response.data;
              setProduct({
                name: i18n.language === 'ar' ? data.Ar.Product_Title : data.En.Product_Title,
                price: data.Prices[selectedCurrency],
                href: '#',
     
                images: [
                  { id: 1, src: data.Images.Main, alt: 'Main product image' },
                  ...data.Images.Thumbnail.map(img => ({
                    id: img.ID,
                    src: img.URL,
                    alt: 'Thumbnail image',
                  })),
                ],
                description: i18n.language === 'ar' ? data.Ar.Product_Description : data.En.Product_Description,
                features: data[i18n.language === 'ar' ? 'Ar' : 'En'].Features,
              });
              handleCurrencyChange(data['default_currency']);
              handleLanguageChange(data['default_language']);
              localStorage.setItem("WPData", JSON.stringify(data, null, 2));
            })
            .catch(error => console.error('Error fetching product data:', error));
       // }, [selectedCurrency]);
  
   //   if (VCode)
    //  {
        
   //   }
    //  if (VCode) return;
  
  
      const payload = {
       VCode: localStorage.getItem("VCode")? localStorage.getItem("VCode") : "",
        WorkspaceP_Code: paramValue,
        page: "MainPage",
        url: window.location.href,
        queryParam: window.location.search,
        screenWidth: window.innerWidth,
        screenHeight: window.innerHeight,
      };
  
      const ret = await axios.post(
        "https://wrqmal.com/api/Visitors_Page/create",
        payload
      );
      console.log(payload);
      console.log("Response:", ret.data);
      if (ret.data && ret.data.VCode) {
        localStorage.setItem("VCode", ret.data.VCode);
      } else {
        alert("System Error, please try again later");
        console.error("VCode not returned from API:", ret.data);
      }
    } catch (error) {
      console.error("Error logging page visit:", error);
    }
  };
  
  console.log({ country, state, city });
//console.log(countriesList);
  return isLoaded ? (
    <div className="bg-gray-50">
      <header className="fixed top-0 left-0 right-0 bg-white z-50 shadow-md">
        <Disclosure as="nav" className="bg-white">
          <div className="relative bg-white">
            <div className="flex h-16 items-center justify-between">
              <div
                className={`absolute inset-y-0 ${
                  isRtl ? "right-0" : "left-0"
                } flex items-center sm:hidden `}
              >
                {/* Mobile menu button */}
                <Disclosure.Button className="inline-flex items-center justify-center p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="sr-only">Open main menu</span>
                  <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  <XMarkIcon className="hidden h-6 w-6" aria-hidden="true" />
                </Disclosure.Button>
              </div>
              <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start text-left">
                <div
                  className={`ml-4 flex flex-shrink-0 items-center ${
                    isRtl ? "mr-2" : ""
                  }`}
                >
                  <a
                    href="https://tailblocks.cc"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      className="w-10 h-10 text-white p-2 bg-indigo-500 rounded-full"
                      viewBox="0 0 24 24"
                    >
                      <path d="M12 2L2 7l10 5 10-5-10-5zM2 17l10 5 10-5M2 12l10 5 10-5"></path>
                    </svg>
                  </a>
                  <span
                    className={`ml-2 text-xl text-gray ${isRtl ? "mr-2" : ""}`}
                  >
                    Excellent Brands
                  </span>
                </div>
                <div className="hidden sm:ml-6 sm:flex sm:space-x-4">
                  <LanguageSelector
                 onLanguageChange={handleLanguageChange}
                    className="ml-4 inline-flex w-32 items-center justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  />
              
                </div>
              </div>
              {/* Cart button positioned to the right */}
              <div
                className={`absolute ${
                  isRtl ? "left-0" : "right-0"
                } top-1/2 transform -translate-y-1/2 mr-4 ml-4`}
              >
                <button
                  onClick={() =>
                    navigate("/shippingINFO", { state: { cartItems } })
                  }
                  className="inline-flex items-center justify-center"
                >
                  <ShoppingBagIcon className="h-5 w-5 mr-2" />
                  {totalQuantity}
                </button>
              </div>
            </div>
          </div>
          <Disclosure.Panel className="sm:hidden">
            <div className="px-20 pb-4 flex flex-row">
              <LanguageSelector
                onLanguageChange={handleLanguageChange}
                className="ml-4 inline-flex w-32 items-center justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              />
              <CurrencySelector
              //  selectedCurrency={currency}
              //  onCurrencyChange={handleCurrencyChange}
                className="ml-4 inline-flex w-32 items-center justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              />
            </div>
          </Disclosure.Panel>
        </Disclosure>
      </header>
      <button
          type="button"
          onClick={() => {
            window.history.back();
          }}
          className="px-4 py-2 mx-2 bg-blue-500 text-white font-semibold rounded-lg hover:bg-blue-600 transition duration-300"
        >
          {t("Back")}
        </button>
      <div id="map" style={containerStyle}></div>
      <div className="p-4 ml-4">
      <div className = "flex flex-col items-center">
      <button
          type="button"
          onClick={handleRequestLocation}
          className=" bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full"
        >
          {t("Current Location")}
        </button>

      </div>
     
        <h2 className="text-lg font-medium text-gray-900">
          {t("Shipping Information")}
        </h2>
        <input
          type="text"
          value={addressLine1}
          onChange={(e) => setAddressLine1(e.target.value)}
          placeholder={t("Address1")}
          className="mt-1 block w-full border border-gray-300 rounded-lg p-2"
        />
        {errors.addressLine1 && (
          <p className="text-red-500">{errors.addressLine1}</p>
        )}
        <input
          type="text"
          value={addressLine2}
          onChange={(e) => setAddressLine2(e.target.value)}
          placeholder={t("Address2")}
          className="mt-1 block w-full border border-gray-300 rounded-lg p-2"
        />
        {errors.addressLine2 && (
          <p className="text-red-500">{errors.addressLine2}</p>
        )}
         <Select value={country} className="mt-1 block w-full border border-gray-300 rounded-lg p-2" placeholder={country=="" ? t("selectCountry") : country} options={countriesOptions} onChange={(e)=>{
           setState(null);
           setCity(null);
           setCountry(e.value);
          if (e.value === "Saudi Arabia") {
            setShowSaudiRelated(true);
          } else {
            setShowSaudiRelated(false);
          }
          getShippingPriceDetails(e.value,"Other");
          if (e.value !== country) {
           
          //  setCountryid(e.id);
            setSelectedDistrict("")
          }
          if (e.value in citiesOptions)
          {
            setCityOptions(citiesOptions[e.value]);
          }
          else
          {
            setCityOptions([]);
          }
          console.log(e);
          console.log(cityOptions);
          console.log(e.value);
          console.log(citiesOptions[e.value])
         }} />
           {errors.country && <p className="text-red-500">{errors.country}</p>}
         <Select value={city} className="mt-1 block w-full border border-gray-300 rounded-lg p-2" placeholder={city=="" ? t("selectCity") : city} options={cityOptions} onChange={(e) => {
            if (e.value !== city) {
              setCity(e.value);
              getShippingPriceDetails(country,city);
              setSelectedDistrict("");
              if (e.value in districtsOptions)
                {
                  setDistrictOptions(districtsOptions[e.value]);
                }
                else
                {
                  setDistrictOptions([]);
                }
                //getShippingPriceDetails(country,"Other");
            //  getShippingOptions();
            }}} />

        {errors.city && <p className="text-red-500">{errors.city}</p>}



        {showSaudiRelated && (
          <>
           <Select value={district}  className="mt-1 block w-full border border-gray-300 rounded-lg p-2" placeholde ={district=="" ? t("selectDistrict") : district} options={districtOptions} onChange={(e) => {
            if (e.name !== city) {
              setCity(e.name);
              setSelectedDistrict("");
            //  getShippingOptions();
            }}} />

            {errors.district && (
              <p className="text-red-500">{errors.district}</p>
            )}
            </>
            )}
            {showSaudiRelated && shortCode !="" && (
              <>
            <input
              type="text"
              value={shortCode}
              onChange={(e) => setShortCode(e.target.value)}
              placeholder={t("ShortCode")}
             // className="border p-2 w-full mb-2"
               className="mt-1 block w-full border border-gray-300 rounded-lg p-2"
            />
            {errors.shortCode && (
              <p className="text-red-500">{errors.shortCode}</p>
            )}
          </>
        )}

        <input
          type="text"
          value={postCode}
          onChange={(e) => setPostCode(e.target.value)}
          placeholder={t("Postal Code")}
          className="border p-2 w-full mb-4"
        />
        {errors.postCode && <p className="text-red-500">{errors.postCode}</p>}

        {/* <button onClick={handleAddressSubmit} className="bg-blue-500 text-white px-4 py-2">
          {t('Submit')}
        </button> */}
        {showError && <p className="text-red-500">{showError}</p>}
        {message && <p className="text-green-500">{message}</p>}
      </div>

      <div className="ml-4 p-4">
        <h2 className="text-lg font-medium text-gray-900">
          {t("Contact information")}
        </h2>
        <div className="sm:col-span-2 mt-4">
          <label
            htmlFor="first-name"
            className="block text-sm font-medium text-gray-700"
          >
            {t("First name")}
          </label>
          <div className="mt-1">
            <input
              type="text"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              placeholder=  {t("First name")}
              className="mt-1 block w-full border border-gray-300 rounded-lg p-2"
              required
            />
            {errors.firstName && (
              <p className="text-red-600">{errors.firstName}</p>
            )}
          </div>
        </div>
        <div className="sm:col-span-2 mt-4">
          <label
            htmlFor="last-name"
            className="block text-sm font-medium text-gray-700"
          >
            {t("Last name")}
          </label>
          <div className="mt-1">
            <input
              type="text"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              placeholder={t("Last name")}
              className="mt-1 block w-full border border-gray-300 rounded-lg p-2"
              required
            />
            {errors.lastName && (
              <p className="text-red-600">{errors.lastName}</p>
            )}
          </div>
        </div>
        <div className="mt-4">
          <label
            htmlFor="email-address"
            className="block text-sm font-medium text-gray-700"
          >
            {t("Email address")}
          </label>
          <div className="mt-1">
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder= {t("Email address")}
              className="mt-1 block w-full border border-gray-300 rounded-lg p-2"
              required
            />
            {errors.email && <p className="text-red-600">{errors.email}</p>}
          </div>
        </div>
        <div className="sm:col-span-2 mt-4">
          <label
            htmlFor="phone"
            className="block text-sm font-medium text-gray-700"
          >
            {t("Phone")}
          </label>
          <div className="mt-1">
            <PhoneInput
              international = {false}
              placeholder={t("PhoneInput")}
              value={phone}
              onChange={setPhone}
            />
            {errors.phone && <p className="text-red-600">{errors.phone}</p>}
          </div>
        </div>
      </div>

      <div className="ml-4 p-4 border-t border-gray-200 pt-10">
        <fieldset>
          <legend className="text-lg font-medium">
            {t("Delivery Method")}
          </legend>
          <RadioGroup
            value={selectedDeliveryMethod}
            onChange={setSelectedDeliveryMethod}
            className="mt-4"
          >
            <div className="space-y-4">
              {deliveryMethods.map((method) => (
                <RadioGroup.Option
                  key={method.id}
                  value={method}
                  className={({ active, checked }) =>
                    `relative flex cursor-pointer rounded-lg border p-4 focus:outline-none ${
                      active ? "ring-2 ring-indigo-500" : ""
                    } ${
                      checked
                        ? "bg-indigo-50 border-indigo-600"
                        : "border-gray-200"
                    }`
                  }
                >
                  {({ active, checked }) => (
                    <>
                      <div className="flex-shrink-0">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          className={`h-6 w-6 ${
                            checked ? "text-indigo-600" : "text-gray-400"
                          }`}
                          viewBox="0 0 24 24"
                        >
                          <path d="M5 12l5 5L20 7"></path>
                        </svg>
                      </div>
                      <div className="ml-3 flex-1">
                        <p className="text-sm font-medium text-gray-900">
                          {i18n.language ==="ar" ? method.titles.ar : method.titles.en}
                        </p>
                        <p className="text-sm text-gray-500">
                          {method.descriptions[i18n.language]}
                        </p>
                      </div>
                      <div className="ml-4 flex-shrink-0 text-sm font-medium text-gray-900">
                        ${currencyConverter("USD",selectedCurrency,method.default_fees)}
                      </div>
                    </>
                  )}
                </RadioGroup.Option>
              ))}
                {deliveryMethods.length==0 && city && (
          <>
          Delivering to selected country and city is not available at this moment
          </> )}
              
              {errors.deliveryMethod && <p className="text-red-500">{errors.deliveryMethod}</p>}

            </div>
          </RadioGroup>
        </fieldset>
      </div>
      {/* Order summary */}
      <div className="ml-4 p-4 lg:mt-0">
        <h2 className="text-lg font-medium text-gray-900">
          {t("Order Summary")}
        </h2>
        <div className="mt-4 rounded-lg border border-gray-200 bg-white shadow-sm">
          <h3 className="sr-only">Items in your cart</h3>
          <ul className="divide-y divide-gray-200">
            {product.map((item) => (
              <li key={item.id} className="flex py-6">
                <div
                  className={`h-24 w-24 flex-shrink-0 overflow-hidden rounded-md border border-gray-200 ml-2 ${
                    isRtl ? "mr-2 " : ""
                  }`}
                >
                  <img
                    src={item.imageSrc}
                    alt={item.imageAlt}
                    className="h-full w-full object-cover object-center"
                  />
                </div>
                <div className="ml-4 flex flex-1 flex-col">
                  <div>
                    <div className="flex justify-between text-base font-medium text-gray-900">
                      <h3>
                        <a href={item.href}>{item.title}</a>
                        <div className="mt-4 flex items-center text-left">
                          <label
                            htmlFor="quantity"
                            className={`block text-md font-bold text-gray-900 mt-2 ${
                              isRtl ? "ml-2" : ""
                            }`}
                          >
                            {t("Quantity")}
                          </label>
                          <div className="flex items-center ml-2">
                         
                           
                          
                          </div>
                        </div>
                      </h3>
                      <div>
                        <p className="mr-2">
                          {" "}
                          {currency} {item.price} * {item.quantity}
                        </p>
                        <button
                          type="button"
                          onClick={() => {
                            deleteItem(item);
                          }}
                          className={`px-3 py-1 mt-3 border border-gray-300 bg-white rounded-l-md ${
                            isRtl ? "rounded-r-md" : ""
                          }`}
                        >
                          {t("Delete")}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
          {errors.cart && <p className="text-red-500 m-3">{errors.cart}</p>}
          <dl className="space-y-6 border-t border-gray-200 px-4 py-6 sm:px-6">
            <div className="flex items-center justify-between text-base font-medium text-gray-900">
              <p>{t("Subtotal")}</p>
              <p>
                {currency} {calculateTotal().subtotal.toFixed(2)}
              </p>
            </div>
            <div className="flex items-center justify-between text-base font-medium text-gray-900">
              <p>{t("Shipping")}</p>
              <p> {currency} {selectedDeliveryMethod
                  ? currencyConverter("USD",selectedCurrency,selectedDeliveryMethod.default_fees)
                  : t("Select delivery method")}
              </p>
            </div>
            <div className="flex items-center justify-between text-base font-medium text-gray-900">
              <p>{t("VAT") } (%{ vatPerc*100})</p>
              <p>
                {currency} {calculateTotal().VAT.toFixed(2)}
              </p>
            </div>
            <div className="flex items-center justify-between text-base font-medium text-gray-900">
              <p>{t("Total")}</p>
              <p>
                {currency} {calculateTotal().total.toFixed(2)}
              </p>
            </div>
          
          </dl>
          <div className="border-t border-gray-200 px-4 py-6 sm:px-6">
            <button
              onClick={handleAddressSubmit}
              type="submit"
              className="w-full rounded-md border border-transparent bg-indigo-600 px-4 py-3 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-50"
            >
              {t("Confirm order")}
            </button>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <HashLoader
    color="#679cd0"
    size={100}
  />
  );
};

export default Map;
