export const saudiArabiaDistricts = {
  RIYADH: [
    { ar: "عليا", en: "Olaya" },
    { ar: "الملز", en: "Al Malaz" },
    { ar: "النخيل", en: "Al Nakheel" },
  ],
  JEDDAH: [
    { ar: "السلامة", en: "Al Salamah" },
    { ar: "الروضة", en: "Al Rawdah" },
    { ar: "الحمراء", en: "Al Hamra" },
  ],
  DAMMAM: [
    { ar: "الخبر", en: "Khobar" },
    { ar: "القطيف", en: "Qatif" },
    { ar: "الدمام الشمالية", en: "Northern Dammam" },
  ],
  MAKKAH: [
    { ar: "العزيزية", en: "Al Aziziyah" },
    { ar: "الشوقية", en: "Al Shoqiyah" },
    { ar: "المسفلة", en: "Al Misfalah" },
  ],
  MEDINA: [
    { ar: "المدينة القديمة", en: "Old City" },
    { ar: "قباء", en: "Quba" },
    { ar: "العوالي", en: "Al Awali" },
  ],
  TABUK: [
    { ar: "البلد", en: "Old Town" },
    { ar: "الخالدية", en: "Khalidiya" },
    { ar: "الحي الجنوبي", en: "Southern District" },
  ],
  ABHA: [
    { ar: "المطار", en: "Al Matar" },
    { ar: "الحيلة", en: "Al Hailah" },
    { ar: "السعادة", en: "Al Sa’adah" },
  ],
  JIZAN: [
    { ar: "الشاطئ", en: "Shati" },
    { ar: "المنطقة المركزية", en: "Central Area" },
    { ar: "مركز الكربوس", en: "Al-Karbous Center" },
  ],
  NAJRAN: [
    { ar: "المغرز", en: "Al-Mughariz" },
    { ar: "المنطقة الوسطى", en: "Central Area" },
    { ar: "الحي الشمالي", en: "Northern District" },
  ],
  BURAYDAH: [
    { ar: "الضباب", en: "Al Dhubbab" },
    { ar: "الجرية", en: "Al Jurayyah" },
    { ar: "العين", en: "Al Ain" },
  ],
};
